@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap");
:root {
  --primary-colorHover: rgb(224, 0, 19);
  --primary-color: rgba(227, 55, 69, 1);
  --black: #000000;
  --white: #fff;
  --Anti-flash-white: #f0f0f0;
  --text-grey: rgba(83, 89, 105, 1);
  --light-grey: rgb(243, 243, 243);
  --green: rgba(38, 126, 61, 0.932);
  --Hovergreen: rgb(38, 126, 61);
  --light-pink: rgba(250, 248, 248, 1);
  --text-black: rgba(42, 45, 50, 1);
  --secondary-black: rgba(42, 45, 50, 1);
  --orange-Color: rgba(243, 120, 53, 1);
  --light-black: #535969;
  --scrollr-gray: #c1c1c1;
  --brand-color: hsl(46, 100%, 50%);
  --light-blue: #edf4ff;
  /* Fonts */
  --font-title: "Montserrat", sans-serif;
  --font-text: "Lato", sans-serif;
}

.App {
  font-family: "Roboto";
}

.flexRowCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.ellipsis {
  white-space: initial;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--black);
}

.ellipsis2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
  height: 2.6em;
  line-height: 1.3em;
}

.hidden {
  display: none !important;
}
::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollr-gray) !important;
  border-radius: 6px !important;
}
@media only screen and (max-width: 767px) {
  .collections_sections_heading h3 {
    font-size: 23.39px;
    font-weight: 600;
    line-height: 28.07px;
  }
  .collections_sections_heading h6 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  .popularVenues_heading_container h3 {
    font-size: 23.39px;
    font-weight: 600;
    line-height: 28.07px;
  }
  .city_description2 h6 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  }
  .pagination_controls a {
    font-size: 12px;
    font-weight: 400;
    line-height: 28.07px;
  }
  /* .black_section_carousel h6 {
    font-size: 30px;
    font-weight: 400;
    line-height: 38.11px;
  } */
  .black_section_carousel p {
    font-size: 16.78px;
    font-weight: 400;
    line-height: 28.77px;
  }
  .linear_progressBar_section {
    padding-left: 05px;
  }
}
.hidden{
  display: none !important;
}