/*-----------------------------------------------New data------------------------------------*/

.terms_imageContainer {
  margin: 2rem 0rem;
  h3 {
    font-size: 30px;
  }
}
.ContrrTnC {
  font-family: "Source Sans 3", serif !important;
}
.headingAgremnt {
  margin-bottom: 1.5rem;

  h3 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 3px;
  }
  h4 {
    font-size: 16px;
    font-weight: 600;
  }
}

.topContnt {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  p {
    line-height: 25px;
    font-size: 16px;
    font-weight: 500;
    color: var(--text-black);
    margin: 0;
  }

  span {
    margin: 0 3px;
    font-size: 16px;
    font-weight: 700;
    color: var(--text-black);
  }

  a {
    text-decoration: none;
    color: var(--primary-color);
    &:hover {
      color: var(--primary-colorHover);
    }
  }
}

.centerConttntt,
.centerConttntt2,
.centerConttntt3 {
  margin-top: 2rem;
  h2 {
    font-size: 30px;
  }
  P {
    margin-top: 0.7rem;
    font-weight: 400;
  }

  li {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
    span {
      margin: 0 3px;
      font-size: 16px;
      font-weight: 700;
    }
  }
  a {
    text-decoration: none;
    color: var(--primary-color);
    &:hover {
      color: var(--primary-colorHover);
    }
  }
}
.firstPteg {
  margin-top: 1.5rem !important;
}
.centerConttntt3 {
  p {
    margin-top: 2rem;
    font-weight: 600;
  }
  .secondPtag {
    margin-top: 1rem;
  }
}
.grenMArkicnnn,
.redMarkIcn {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  margin: 4px 0;
  img {
    height: 14px;
    width: 14px;
  }
  span {
    font-weight: 600;
  }
}
.redMarkIcn {
  margin: 5px 0 !important;
  img {
    height: 18px;
    width: 18px;
  }
}
.fouthHad {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  .firstPteg {
    margin-top: 0.7rem !important;
    margin-bottom: 0.3rem;
  }
}

.emailLbll {
  margin-top: 0.5rem;
  a {
    margin-left: 5px;
    text-decoration: none;
    color: var(--primary-color);
    &:hover {
      color: var(--primary-colorHover);
    }
  }

  span {
    color: var(--text-black);
    font-weight: 500;
  }
}

/*--------------------PP----------------*/

.firstPtegPrivacy {
  margin-top: 0.3rem !important;
  font-weight: 600 !important;
}
.firstPtegPrivacy2 {
  margin-top: 0.5rem !important;
  font-weight: 600 !important;
}
.firstPtegPrivacy3 {
  margin-top: 1rem !important;
}

@media screen and (max-width: 768px) {
  .centerConttntt,
  .centerConttntt2,
  .centerConttntt3 {
    h2 {
      font-size: 20px;
    }
    p {
      font-size: 15px !important;
    }
    span {
      font-size: 15px !important;
    }
    li {
      font-size: 15px !important;
    }
  }

  .topContnt {
    p {
      font-size: 15px !important;
    }
    span {
      font-size: 15px !important;
    }
  }
  .grenMArkicnnn {
    label {
      font-size: 15px !important;
    }
    span {
      font-size: 15px !important;
    }
  }
  .lablClsss {
    label {
      font-size: 15px !important;
    }
    span {
      font-size: 15px !important;
    }
  }
  .emailLbll {
    a {
      font-size: 15px !important;
    }
  }
}

.listStylineNonee {
  li {
    list-style: none !important;
  }
}
