.searchBar_container {
  display: flex;
  flex-direction: row;
  /* justify-content: flex-start; */
  border-radius: 3px;
  padding: 0.2rem 1rem;
  background-color: var(--white);
  gap: 0.1rem;
  width: 100%;
}

.locationSection_searchbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  row-gap: 1.5rem;
  /* width: 100%; */
  background-color: var(--white);
}

.locationSection_searchbar p {
  color: var(--text-grey);
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
}

.seachVenue_section_searchbar input {
  border: none;
  font-size: 14px;
  color: var(--text-grey);
  margin-left: 0.3rem;
  outline: white !important;
  background-color: var(--white);
  width: 100%;
}

.seachVenue_section_searchbar input:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none;
}

.seachVenue_section_searchbar input::placeholder {
  font-family: "Roboto";
  font-size: 14px;
  color: var(--text-grey);
}

.seachVenue_section_searchbar {
  background-color: var(--white);
  align-items: center;
  display: flex;
  padding: 0.2rem 0.5rem;
  border-radius: 3px;
  flex-direction: row;
  width: 100%;
  height: 50px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.searchBar_wrapper {
  display: flex;
  flex-direction: column;
}

.itemSearch {
  display: flex;
  gap: 1rem;
  padding: 0.3rem 0.3rem;
  text-decoration: none;
  border-bottom: 0.5px solid grey;
  flex-direction: row;
}

.searchItems a {
  width: 100%;
  text-decoration: none;
}

.itemSearch img {
  width: 45px !important;
  height: 45px !important;
  margin-left: 0.5rem;
  border-radius: 15%;
  aspect-ratio: 1;
}

.search_result_text p {
  margin: 0;
  font-family: "Roboto";
  font-size: 12px;
  text-decoration: none;
  color: var(--text-grey);
}

.search_result_text h6 {
  margin: 0;
  color: var(--text-grey);
}

.serachBxx {
  position: relative;
  height: 100%;
  width: 100%;
}

.searchItems {
  border-radius: 8px;
  width: 100%;
  /* position: absolute; */
  background-color: var(--white);
  left: 0px;
}

.drop_down_searchBar {
  position: relative;
  margin-top: 0.5rem;
}

.react-date-picker__wrapper {
  border: none !important;
  box-shadow: none !important;
}

.react-date-picker__inputGroup__input {
  border: none !important;
  outline: none !important;
}

.react-date-picker__button svg {
  display: none !important;
}

.CalendarSection_searchbar {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.react-date-picker__inputGroup {
  width: fit;
}

.react-date-picker__button:enabled {
  display: none;
}

.letsgo_button button {
  height: 100%;
  font-weight: 600;
  background-color: var(--primary-color);
  font-family: "Roboto";
  font-size: 14px;
  width: 100% !important;
  white-space: nowrap;
  border: none;
  border-radius: 3px;
  color: var(--white);
  padding: 0.2rem 1.5rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: all ease-in 0.1s;
}

.letsgo_button button:hover {
  background-color: var(--primary-colorHover);
  color: var(--white);
}
.are_you_a_venue_owner a {
  background-color: var(--primary-color);
}
.are_you_a_venue_owner a:hover {
  background-color: var(--primary-colorHover);
  color: var(--white);
}
.searchBar_wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: row;
  gap: 0.3rem;
}

.searchBar_wrapper img {
  margin-left: 5px;
  width: 15px;
  height: 15px;
}

.datepicker {
  z-index: 100;
}

@media (max-width: 830px) {
  .verticle_line {
    margin: 0 0.5rem;
  }

  .city_description {
    position: absolute;
    bottom: 5px !important;
    left: 10px !important;
    cursor: pointer;

    h6 {
      margin: 0;
      font-size: 14px;
      color: var(--white);
    }

    p {
      margin: 0;
      font-size: 14px;
      color: var(--primary-color);
    }

    span {
      gap: 0.2rem;
    }
  }

  .heroSection {
    margin-top: 1rem !important;
  }

  .datepicker {
    padding: 0.2rem 0.5rem;
  }

  .padding04 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .CalendarSection_searchbar {
    background-color: var(--white);
    color: var(--text-grey);
    justify-content: left;
    padding: 0rem;
    border-radius: 5px;
  }

  .seachVenue_section_searchbar {
    /* width: 75%; */
    width: 100%;
    height: 100%;
    padding: 0.4rem 0.5rem;
    border-radius: 3px;
  }

  .locationSection_searchbar {
    width: 100%;
    justify-content: left;
    padding: 0;
    border-radius: 3px;
  }

  .letsgo_button button {
    height: fit-content;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0.4rem 1rem;
    height: 100%;
  }

  .letsgo_button {
    width: 25%;
  }

  .searchBar_wrapper {
    flex-direction: column;
    justify-content: center;
    gap: 0.3rem;
    padding: 0 1.5rem;
  }

  .seAll_span {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .searchBar_container {
    display: none;
  }
}

.itemSearch {
  display: flex;
  gap: 1rem;
  padding: 0.3rem 0.3rem;
  text-decoration: none;
  border-bottom: 0.5px solid grey;
  flex-direction: row;
}

.searchItems a {
  width: 100%;
  text-decoration: none;
}

.searchItems a {
  width: 100%;
  text-decoration: none;
}

.searchItems {
  border-radius: 3px;
  width: 100%;
  /* position: absolute; */
  background-color: var(--white);
  z-index: 99999999999;
  max-height: 165px;
  overflow: hidden;
  overflow-y: auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.ShowResultSerach {
  justify-content: start !important;
}
.SErchBarrr {
  width: 100% !important;
  margin: 0 !important;
}
.width100per {
  width: 100%;
}
.NoDataFOund {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 7px;
}

.locBtn {
  display: flex;
  width: 100%;
  gap: 0.5rem;
}

.css-b62m3t-container {
  width: 100%;
}

.css-1fdsijx-ValueContainer,
.css-1kfcnnd-control {
  width: 100% !important;
  justify-content: space-between;
}
.moreTextLessText {
  display: flex;
  margin-top: 3px;
  margin-left: 6px;
  font-size: 14px;
  color: var(--primary-color);
}
.IagryText {
  font-size: 14px !important;
}
