.PatnerHeroSection {
  position: relative;
  overflow: hidden;
  max-height: 100%;
  min-height: 75vh;
  background: url("../../Assets/patnerHerobg3.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    padding-top: 2rem;
    text-align: center;
    max-width: 70%;
    position: relative;
    z-index: 20;
    margin-bottom: 1rem;
  }
  p {
    text-align: center;
  }
  img {
    height: 100%;
    width: 100%;
  }
}

.registerRestBtn {
  padding-bottom: 1rem;
  margin-top: 1rem;
  button {
    border: none;
    padding: 0.6rem 2rem;
    border-radius: 5px;
    background-color: var(--primary-color);
    color: var(--white);
    transition: background-color 0.3s ease;
    &:hover {
      background-color: var(--primary-colorHover);
    }
  }
}

.getSTartDiv {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: -2.5rem;

  .getstartData {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 1.5rem;
    background-color: var(--white);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

    h2 {
      font-size: 20px;
      font-weight: 500;
    }

    p {
      font-size: 15px;
      font-weight: 400;
      color: var(--text-grey);
    }
    span {
      font-size: 14px;
      color: var(--black);
      font-weight: 500;
    }
  }
}

.gstAskDiv {
  display: flex;
  flex-direction: column;
  label {
    margin-top: 3px;
    color: var(--text-grey);
    font-weight: 400;
    font-size: 13px;
  }
}
.marginClss {
  margin-top: 3px;
}

/*-------------------Why Should Section--------------*/

.WHyShouldSection {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
}

.whyShouldHeading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.lin1,
.lin3 {
  height: 1px;
  background-color: var(--text-grey);
  flex-grow: 1;
}

.lin2 {
  margin: 0 10px;
}

.shouldCardDiv {
  margin-top: 3rem;
}

.ShouldCardData {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    height: 90px;
    width: 90px;
    margin-bottom: 1.4rem;
  }
  h3 {
    font-size: 20px;
    font-weight: 500;
  }
  p {
    font-size: 15px;
    font-weight: 400;
    color: var(--text-grey);
    text-align: center;
  }
}

/*---------------------Get Help For Patner Page--------------------*/

.patnerGetHelp {
  padding: 3rem 1rem;
  margin-top: 3rem;
  background-image: url("/src/Assets/getHelpBg.png") !important;
  label {
    font-size: 15px;
  }
  input {
    font-size: 15px;
    padding: 0.5rem;
    &:hover {
      border: 1px solid var(--primary-color);
    }
  }
  span {
    font-size: 14px;
    color: var(--primary-color);
  }
}
.contrPatnerForm {
  padding: 2rem;
  background-color: var(--white);
  border-radius: 8px;
}
.PatnerResAddrs {
  height: 100px;
  resize: none;
}

.formSubmtBtnPatnr {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  button {
    padding: 0.5rem 3rem;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    color: var(--text-grey);

    background-color: var(--Anti-flash-white);
    transition: background-color 0.3s ease;
    &:hover {
      background-color: var(--primary-colorHover);
      color: var(--white);
    }
  }
}

.nedHlpContnt {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  h2 {
    color: var(--white);
  }
  p {
    font-weight: 400;
    line-height: 27px;
    margin-top: 1rem;
    color: var(--white);
  }
  strong {
    font-size: 18px;
    margin-bottom: 1rem;
  }
  a {
    text-decoration: none;
    color: var(--primary-color);
    &:hover {
      color: var(--primary-colorHover);
    }
  }
}

.contUssContnt {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  p {
    margin: 0rem;
  }
}
.patnerTg2 {
  font-size: 16px !important;
  font-weight: 600 !important;
}

@media screen and (max-width: 768px) {
  .getstartData {
    margin: 0.3rem;
  }
  .PatnerHeroSection {
    background-position: 50%;
    background-size: cover !important;
    h1 {
      font-size: 25px;
      max-width: 100%;
    }
    p {
      font-size: 14px;
    }
  }

  .getSTartDiv {
    .getstartData {
      justify-content: center;
      align-items: center;
    }
  }

  .getSTartDiv {
    & .getstartData {
      h2 {
        font-size: 17px;
      }
      p {
        font-size: 14px;
      }
    }
  }

  .ShouldCardData {
    h3 {
      font-size: 17px;
    }
    P {
      font-size: 14px;
    }
  }
  .patnerGetHelp {
    padding: 2rem 0rem;
  }
  .contrPatnerForm {
    padding: 1rem;
    margin-top: 1rem;
  }
  .rowReverse {
    display: flex;
    flex-direction: column-reverse;
  }
}
