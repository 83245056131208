.person_name {
  color: var(--white);
  font-family: "Roboto";
  font-size: 20px;
  width: fit-content;
  padding: 3rem 3rem;
  border-radius: 50% 50%;
  aspect-ratio: 1;
  background-color: var(--light-black);
  position: relative;
}

.person_name h3 {
  margin: 0;
}

.profile_picture_section {
  background-color: #ffe9eb;
  padding: 3rem 3rem;
  border-radius: 11px;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.header_Section_profile_page {
  margin-top: 2rem;
}

.profile_name_text_section {
  margin-left: 1rem;
}

.profile_name_text_section p {
  margin: 0;
  display: flex;
  color: var(--text-grey);
  flex-direction: column;
}

.update_button_profile_page {
  margin-left: auto;
}

.update_button_profile_page a {
  text-decoration: none;
  color: var(--white);
  font-family: "Roboto";
  padding: 0.6rem 1rem;
  border-radius: 5px;
  background-color: var(--light-black);
  transition: 0.1s ease-in;
}

.update_button_profile_page a:hover {
  background-color: var(--primary-color);
}

.tabs {
  padding: 0.5rem 0.5rem;
}

.vertical-tabs {
  padding-bottom: 10px;
  height: 100%;
  background-color: white;
  display: flex;
  border-radius: 5px;
}

.react-tabs__tab-panel--selected {
  width: 100%;
  border-radius: 5px;
}

.container_venues_profile_page {
  background: rgba(244, 244, 244, 1);
  padding: 1.5rem 1rem;
  border-radius: 8px;
}

/* .container_venues_profile_page h6 {
  margin-bottom: 1.5rem;
} */

.tab-list {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0rem;
  margin: 0;
}

.tab-list h3 {
  padding-left: 20px;
}

.tab h6 {
  font-weight: 400;
}

.tab {
  text-align: left;
  font-family: "Roboto";
  padding: 10px 20px;
  cursor: pointer;
  outline: none;
  list-style: none;
  border-radius: 5px;
  /* margin-bottom: 0.5rem; */
}

.tab img {
  margin-right: 0.3rem;
}

.tab:last-child {
  /* border-bottom: none; */
  width: fit-content;
  height: fit-content;
}

.active-tab {
  background-color: var(--light-grey);
  width: fit-content;
  height: fit-content;
}

.active-tab h6 {
  margin-bottom: 0;
  font-weight: 500;
}

.TabPanel {
  margin: 0;
  padding: 0;
}

.tab-panel {
  display: block;
}

.back-button {
  display: none;
  /* Hide back button by default */
}

.reservations_section {
  display: flex;
  gap: 2rem;
  padding-bottom: 1rem;
}

.reservations_section button {
  border: none;
  background: none;
}

.reservations_section hr {
  padding: 0;
  margin: 0rem 0rem !important;
}

.favourite_section {
  padding: 1rem 3rem;
}

.settings_section_profile_page {
  padding: 2rem 2rem;
}

.settings_text_profile_page {
  padding: 1rem 1rem;
  border: 1px solid grey;
  border-radius: 5px;
  width: 100%;

  p {
    color: var(--text-grey);
  }
}

.toggle_switches_settings {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  span {
    display: flex;
    gap: 0.5rem;
  }
}

/* toggle button  css*/
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 1px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--primary-color);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--primary-color);
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.switch_case {
  display: flex;
  align-items: center;

  p {
    margin: 0;
  }
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* profile section css */
.profile_pafe_form input {
  border-top: none;
  border-right: none;
  box-shadow: none !important;
  border-left: none;
  background: transparent;
  border-radius: 0rem;
  border-bottom: 1.3px solid black;
  margin-bottom: 0.2rem;
  font-weight: 500;
  font-family: "Roboto";
}

.profile_pafe_form input:focus {
  background: transparent;
}

.profile_pafe_form label {
  margin: 0 !important;
}

.birth_date_profile {
  margin-top: auto;
}
.birth_date_profile label {
  padding-bottom: 10px;
}
#phoneNumberProfilePage {
  width: 100%;
}

.react-international-phone-input-container .react-international-phone-input {
  width: 100%;
}

.profile_section {
  padding: 1.3rem 2rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 10px;
}

.radio_buttons_reg_form {
  display: flex;
}

.profile_section label {
  margin-top: 0.5rem;
}

.radio_buttons_reg_form input[type="radio"] {
  /* margin-top: 0.5rem; */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid black;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  position: relative;
  vertical-align: middle;
}

.radio_buttons_reg_form input[type="radio"]:before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--primary-color);
  position: absolute;
  top: 7px;
  left: 7px;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.2s ease-in-out;
}

.radio_buttons_reg_form input[type="radio"]:checked:before {
  transform: translate(-50%, -50%) scale(1);
}

.radio_buttons_reg_form label {
  margin-left: 5px;
  vertical-align: middle;
}

.error-message {
  font-size: 13px;
  color: red;
}

.venue_details_profile_page {
  display: flex;
  gap: 1rem;
  color: var(--light-black);
}
.CnclBtttn {
  cursor: pointer;
  margin-right: 1rem;
  justify-content: end;
}
.people_span {
  display: flex;
  gap: 0.2rem;
  align-items: center;
  cursor: pointer !important;
  h6 {
    cursor: pointer !important;
    color: var(--primary-color);
    margin-bottom: 0;
  }
}

.reservation_text {
  display: flex;
  align-items: center;

  p {
    margin: 0.2rem;
  }
}

.ProfilePage_image_container {
  display: flex;
  align-items: center;

  img {
    border-radius: 7px;
    max-height: 100px;
    max-width: 100px;
    aspect-ratio: 1 !important;
    margin: 0px;
  }
}

.ProfilePage_text_section {
  padding-left: 1rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
  width: 100%;
  display: flex;
  gap: 0.2rem;
  h6,
  span,
  strong {
    cursor: text;
  }
}

.profile_page_upcomming_section_heading {
  margin-left: 1rem;
  margin-bottom: 1.5rem;
}

.container_profile_section_venue {
  display: flex;

  align-items: center;
  padding: 0.8rem;
  cursor: default;
  border-radius: 8px;
  background: var(--white);
  box-shadow: 0px 2px 2px 0px #0000002e;
  /* height: 180px; */
  width: 100%;
}

.cancel_modal_style {
  font-family: "Roboto";
  align-items: center;
  padding: 1rem 1.5rem;

  h5 {
    color: var(--light-black);
  }

  h6 {
    margin-top: 1rem;
    color: var(--primary-color);
  }
}

.buttons_cancel_modal {
  margin-top: 2.5rem;
  display: flex;
  gap: 1rem;

  button {
    border: 1px solid var(--text-grey);
    color: var(--primary-color);
    background-color: var(--white);
    border-radius: 5px;
    padding: 0.2rem 1rem;
  }

  a {
    color: var(--white);
    background-color: var(--primary-color);
    border-radius: 5px;
    padding: 0.2rem 1rem;
    text-decoration: none;
  }
}

.modal-header .btn-close {
  box-shadow: none;
}

.modify_modal_image {
  padding: 0;
  width: fit-content;
  height: fit-content;

  img {
    width: 150px;
    aspect-ratio: 1;
  }
}

/*------------new Favrate Section-------*/
.favourite_section2 {
  position: relative;
  padding: 1rem 1.5rem;
  border-radius: 10px;
  height: auto;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
}

.favourite_section2 h6 {
  margin: 0;
}

.fevorateContanrr {
  position: relative;
  gap: 1rem;
  align-items: center;
  background-color: white;
  margin-top: 1rem;
  overflow: hidden;
  display: flex;

  width: 100%;
  padding: 1rem;

  border-radius: 10px;
  box-shadow: 0px 2px 2px 0px #0000002e;
}

.leftCont {
  display: flex;
  width: 30%;
}

.favImgs {
  position: relative;
  display: flex;

  align-items: center;
  width: 150px;
  height: 150px;

  img {
    height: 100%;
    width: 100%;
  }
}

.SubmttBUTTTOnn {
  border: none;
  color: white;
  font-size: 14px;
  border-radius: 5px;
  padding: 0.4rem 1rem;
  background-color: #e33745;
}

.rightContainer {
  width: 100%;
  height: 100%;
  display: flex;
}

.FaVCardcontent {
  display: flex;
  margin: 0 !important;
  padding-top: 0.5rem;

  height: 100%;

  flex-direction: column;
  justify-content: center;

  p {
    text-align: left;
    font-size: 13px;
    margin: 0;
  }

  h5 {
    font-size: 16px;
  }
}

.AVrageSection {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 5px;
  width: fit-content;
  border-radius: 5px;
  padding: 0.4rem 1.4rem;
  background-color: #f8e8e9;

  label {
    font-size: 14px;

    margin: 0 !important;
  }

  img {
    height: 18px;
    width: 18px;
  }
}

.QuestionTOol {
  height: 12px !important;
  width: 12px !important;
}

.drinksSec {
  padding-top: 10px;
  gap: 8px;
  display: flex;
  padding: 7px;
  flex-wrap: wrap;
  align-items: center;

  label {
    font-size: 14px;
    margin: 0 !important;
  }

  img {
    height: 12px;
    width: 12px;
    margin-right: 3px;
  }
}

.TimingButtons {
  padding-top: 0.4rem;
  position: relative;
  display: flex;
}
.register-venue-form-heading {
  margin-bottom: 25px;
}
.timesBtns {
  color: #535969;
  font-weight: 600;
  position: relative;
  padding: 0.4rem 1rem;
  font-size: 15px;
  background-color: #faf8f8;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  p {
    margin: 0;
  }
}

.childtime {
  color: #535969;
  padding: 0rem 0.4rem;
  border: 0.5px solid #535969;
  top: 14;
  background-color: white;
  z-index: 30;

  border-radius: 3px;
  font-size: 12px;
  position: absolute;
}

/*---DOB*/
.DOBCalander select {
  border-radius: 6px;
  text-align: center;
}
.userProfileForm {
  p {
    font-size: 23px;
    margin-bottom: 0px;
  }
  desc {
    font-size: 14px;
  }
}
@media (max-width: 800px) {
  .fevorateContanrr {
    align-items: center;
    flex-direction: column;
  }

  .ContnnrFavcratCard {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .favImgs {
    width: 100%;
    height: 100%;
  }

  .leftCont {
    width: 100%;
  }
}

.tab-panel {
  display: block;
}

.back-button {
  display: none;
  /* Hide back button by default */
}
.heighClss {
  height: calc(100vh - 110px);
}

.overflowheHightClass {
  height: 70vh;
  overflow-y: auto;
}
@media (max-width: 1062px) {
  .tab-panel.active {
    width: 100%;
    display: block;
    padding: 0.5rem 1rem;
    /* Show the active tab panel */
  }

  .back-button {
    display: block;
    /* Show back button on smaller screens */
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    padding: 0.4rem 4rem;
    font-family: "Roboto";
    border: none;
    border-radius: 5px;
    background-color: var(--secondary-black);
    color: var(--white);
  }
}

@media (max-width: 575px) {
  .profile_picture_section {
    display: flex;
    padding-left: 0.5rem;
    padding-right: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .person_name {
    padding: 2rem;
  }
}
@media (max-width: 768px) {
  .ProfilePage_text_section {
    margin-top: 0.6rem;
  }

  .ProfileTbList {
    overflow: auto;
    flex-direction: row;
    -webkit-overflow-scrolling: touch;
    li {
      white-space: nowrap;
    }
  }
  .ProfileTbList::-webkit-scrollbar {
    display: none;
  }
  .profile_picture_section {
    overflow-x: auto;
  }
}
@media (max-width: 440px) {
  .ProfilePage_text_section {
    padding-left: 0px;
  }
  .container_profile_section_venue {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 850px) {
  .profile_section {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media screen and (max-width: 991px) {
  .header_Section_profile_page {
    margin-top: 0px;
  }
  .heighClss {
    height: calc(100vh - 190px);
  }
  .overflowheHightClass {
    height: 52vh;
  }
  .favourite_section2 {
    height: 63vh !important;
  }
  .ProfileCont {
    height: calc(100vh - 225px);
    overflow: auto;
  }
  .ProfileCont {
    height: calc(100vh - 225px);
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .ProfileCont::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .tabListHow {
    /* flex-direction: row !important; */
    height: fit-content;
    justify-content: space-between;
  }

  .tab h6 {
    margin-bottom: 0;
  }

  .vertical-tabsHor {
    display: block !important;
  }

  .tab-panel.active {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 576px) {
  .padding00 {
    padding: 0;
  }

  .tab {
    padding: 10px 15px;
  }

  .switch_case {
    align-items: flex-start;
  }
}

.marginTop0 {
  margin-top: 0 !important;
}

.LikeImgg {
  width: 24px !important;
}
@media screen and (max-width: 537px) {
  .switch {
    width: 57px;
  }
}
