.venuePage_venueCategory_heading {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.venuePage_venueCategory_heading a,
.venuePage_venueCategory_heading span {
  color: var(--primary-color);
  text-decoration: none;
}

.filters_section {
  margin-top: 2rem;
}

.filters_wrapper {
  background: rgba(245, 245, 245, 1);
  padding: 0.3rem 0.2rem;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.18);
  overflow: auto;
}

.filters_wrapper::-webkit-scrollbar {
  width: 5px !important;
  height: 5px;
}

.filters_wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
  height: 5px;
}

.filters_wrapper::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 25px;
}

.filters_wrapper ul {
  display: flex;
  margin: 0;
  flex-direction: row;
  gap: 0.3rem;
  padding: 0rem 0rem;
  list-style-type: none;
}

#filter_filter_row {
  padding-top: 2px;
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
  background-color: var(--green);
  color: var(--white);
  font-family: "Roboto";
  align-items: center;
  border: none;
}
#filter_filter_row:hover {
  background-color: var(--Hovergreen);
}
.datepicker h6 {
  margin-top: 7px;
}
.datepicker {
  background-color: white;
  border: 1px solid #b6b6b6 !important;
}
.are_you_a_venue_owner h6 {
  font-size: 1.4rem;
}
.datepicker img {
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 3px;
}
#DatePicker_row {
  padding: 2px 30px 2px 30px;
  background-color: var(--white);
  color: var(--text-black);
  font-family: "Roboto";
  align-items: center;
  border: 1px solid #b3b3b3;
}

.custom-date-picker {
  border: none;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  width: 150px;
}

.filters_wrapper ul li {
  border: 1px solid rgba(255, 255, 255, 1);
  border-radius: 5px;
  padding: 0.1rem 0.5rem;
  color: var(--text-grey);
  align-items: center;

  white-space: nowrap;
}

.filters_wrapper ul li:hover {
  /* background-color: whitesmoke;
  border: 1px solid rgba(255, 255, 255, 1); */
  color: var(--white);
}

.found_venues_section_heading h6 {
  color: var(--text-grey);
  font-family: "Roboto";
  font-weight: 600;
}

/* venue page */
.VenuePage_venue_container {
  position: relative;
  /* display: flex; */
  gap: 1rem;
  padding: 0.5rem;
  flex-direction: row;
  border-radius: 10px;
  justify-content: left;
  overflow: hidden;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  box-shadow: 0px 2px 2px 0px #0000002e;
}

@media screen and (max-width: 991px) {
  .venuePage_image_container img {
    width: 100% !important;
  }
}

@media screen and (max-width: 768px) {
  .VenuePage_venue_container {
    flex-direction: column;
  }

  .venuePage_image_container img {
    width: 100%;
  }
}

.VenuePage_venue_container:hover {
  /* box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.18); */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 0px;
}

.venuePage_image_container img {
  width: 100% !important;
  height: 250px;
  object-fit: cover;
  border-radius: 10px;
  aspect-ratio: 1.5 !important;
}

.venuePage_image_container {
  height: 100%;
  border-radius: 10px;
}

#category_venuePage {
  margin: 0;
  width: fit-content;
  padding: 0.2rem 0.4rem;
  border-radius: 5px;
  margin-bottom: 0.2rem;
  background: rgba(248, 232, 233, 1);
}

#facilities_venuePage {
  margin: 0;
  width: fit-content;
  border-radius: 5px;
  font-size: 12px;
}

.venuePage_venue_category_titles {
  display: flex;
  flex-direction: row;
  gap: 4px;
  flex-wrap: wrap;
  margin-top: 0.1rem;
  margin-bottom: 0.5rem;
}

.venueContainer_nameAndAddress {
  display: flex;
  flex-direction: column;
}

.venueContainer_rowtext {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.3rem;
}

.venueContainer_rowtext h6 {
  margin: 0;
}

.venuePage_venue_capacity_wrapper {
  display: flex;
  margin-top: auto;
  flex-direction: row;
  justify-content: center;
  gap: 0.2rem;

  align-items: center;
}

.time_discount_container {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  gap: 1rem;
  justify-content: space-evenly;
}

.time_section p {
  padding: 0.4rem 0.7rem;
  font-family: "Roboto";
  font-weight: 600;
  margin: 0;
  font-size: 16px;
  border-radius: 2px;
  border-radius: 5px;
  background-color: var(--light-pink);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.discount_section {
  display: flex;
  justify-content: center;
}

.discount_section p {
  border: 1px solid var(--secondary-black);
  width: fit-content;
  padding: 0.1rem 0.7rem;
  margin-top: -0.5rem;
  background-color: white;
  z-index: 9;
  border-radius: 5px;
  font-family: "Roboto";
  font-weight: 600;
  font-size: 12px;
}

.avrgPrice {
  font-weight: 500;
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
  width: fit-content;
  padding: 0.2rem 0.8rem;
  border-radius: 5px;
  background: rgba(248, 232, 233, 1);
  font-family: "Roboto";
  font-size: 14px;
}

.venuePage_text_section {
  gap: 2px;
  font-size: 14px;
  width: 100%;
  padding-top: 2rem;
  color: var(--text-black);
  display: flex;
  text-decoration: none;
  flex-direction: column;
}

.venuePage_listyourVenue_section {
  margin-top: 4rem;
}

.facility_item {
  display: flex;
  border-radius: 5px;
  flex-wrap: nowrap;
  padding: 0.2rem 0.4rem;
  gap: 0.2rem;
}

.margin24px {
  margin-bottom: 24px;
}

.searchBarContainer {
  margin: 0.5rem auto;
  width: 90%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 10px;
}

.stars_text_wrapper label {
  width: 100px;
}

.filter-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  font-family: "Roboto";
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 4px;
  outline: none;
  width: 600px;
  max-width: 90%;
}

.filter-modal-overlay {
  background-color: rgba(0, 0, 0, 0.75);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.vertical-tabs {
  display: flex;
  font-family: "Roboto";
  justify-content: left;
}

.vertical-tab-list {
  display: flex;
  padding: 0;
  list-style-type: none;
  flex-direction: column;
  margin-right: 1rem;
  width: 30%;
}

.vertical-tab-list .react-tabs__tab {
  margin-bottom: 0.5rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 5px;
  outline: none;
}

.vertical-tab-list .react-tabs__tab--selected {
  /* background-color: lightgrey; */
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0) 97%,
    var(--primary-color) 20%
  );
}

.modal-footer {
  display: flex;
  justify-content: right;
  margin-top: 20px;
}

.colored_text_verticle_tabs {
  color: var(--primary-color);
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 600;
}

.filters_modal_venuesPage {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
}
.tabpanel {
  padding: 10px 1px;
}
.filters_modal_venuesPage label {
  white-space: nowrap;
  font-family: "Roboto";
  font-size: 16px;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  font-weight: 500;
}
.FilterTablist .tab {
  padding: 4px;
}
.filters_modal_heading {
  font-family: "Roboto";
  font-size: 24px;
}

#filters_modal {
  height: 40vh;
}
.filter_modal_button {
  z-index: 20;
  background-color: white;
}
.filterMOdal .modal-content {
  overflow: hidden;
}

.filter_modal_button button {
  background-color: var(--primary-color);
  color: var(--white);
  border: none;
  font-family: "Roboto";
  font-weight: 400;
  border-radius: 5px;
}

.filter_modal_button button:hover {
  background-color: var(--primary-color);
  color: var(--white);
  border: none;
  font-family: "Roboto";
  font-weight: 400;
  border-radius: 5px;
}

.filters_section_row img {
  width: 15px;
}

.filters_section_row {
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
}

.venuePage_ratingSection {
  display: flex;
  gap: 0.2rem;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  color: var(--white);
  font-weight: 600;
  justify-content: space-between;
  padding: 0.1rem 0.4rem;
}

.venuePage_ratingSection p {
  margin: 0;
  font-size: 14px;
}

.venuePage_ratingSection img {
  width: 15px;
  height: 15px;
}

.venuePage_image_container {
  position: relative;
  width: 100%;
}

.venuePage_ratingSection {
  position: absolute;
  /* Position the rating section absolutely within the container */
  bottom: 10px;
  /* Adjust the bottom position as needed */
  right: 10px;
  /* Adjust the right position as needed */
  background-color: var(--green);
  color: white;

  /* Optional: text color */
  border-radius: 5px;
  /* Optional: rounded corners */
  display: flex;
  /* Align items in a row */
  align-items: center;
  /* Center items vertically */
}

.marginNone {
  margin: 0 !important;

  p {
    margin: 0 !important;
  }
}

.TimingButtons2 {
  padding-top: 0.4rem;
  padding-bottom: 1.5rem;
  position: relative;
  display: flex;
  gap: 0.4rem;
  width: 100%;
  overflow: auto;
}

.LikedHeart {
  top: 15px;
  right: 20px;
  position: absolute;
  z-index: 10;

  button {
    background-color: transparent;
    transition: transform 0.3s ease, opacity 0.3s ease;
    border: none;
  }
}

.LikedHeart button:hover {
  transform: scale(1.2);
  opacity: 0.8;
}

@media screen and (max-width: 576px) {
  .LikedHeart {
    top: 17.7rem;
    img {
      height: 22px;
      width: 22px;
    }
  }
  #filters_modal {
    height: 32vh;
  }
}

.amenityItem {
  display: flex;
  align-items: center;
  padding: 2px;

  img {
    margin-right: 2px;
  }
}

.filters_section_row.selected {
  background-color: var(--primary-color);
  color: var(--white);
  /* change this to your preferred color */
}
/* .tabList{
  gap: 0.5rem;
  width: 100%;
} */
.tabList p {
  cursor: pointer;
}
.filterbutton {
  background-color: #ececec;
}

.tab p.active {
  border: 1px solid black;
  border-radius: 5px;
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0) 97%,
    var(--primary-color) 20%
  );
}
.tab p {
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.tab {
  transition: background-color 0.3s, color 0.3s;
}

.tab.active {
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0) 99%,
    var(--primary-color) 20%
  );
  border: 1px solid black;
}
.tab:last-child {
  width: auto !important;
}
input[type="checkbox"] {
  transform: scale(1.6); /* Adjust the scale factor as needed */
  margin: 10px; /* Optional: adjust margin for spacing */
  margin-top: 7px;
}
/* Add this CSS to your stylesheet */
.filters_modal_venuesPage {
  max-height: 290px; /* Adjust the height as needed */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}
