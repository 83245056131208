.onBoarding_page_wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(/src/Assets/bgOnBoarding.png);
  background-size: cover;
}

.complete_scetion_wrapper_onBoarding {
  border-radius: 8px;
  margin: auto;
  display: flex;
  font-family: "Roboto";
  flex-direction: row;
  justify-content: center;
  justify-content: center;
}

.left_section_onboardingHome {
  overflow: hidden;
  display: flex;
  color: var(--white);
  flex-direction: column;
  align-items: center;
}

.left_section_onboardingHome span {
  overflow: hidden;
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  flex-direction: row;
  align-items: center;
}

.left_section_onboardingHome h1,
.left_section_onboardingHome h3,
.left_section_onboardingHome h6 {
  font-weight: 700;
  margin-bottom: 0;
}

.right_section_onboardingHome h1,
.right_section_onboardingHome h3,
.right_section_onboardingHome h6 {
  margin-bottom: 0;
}

.right_section_onboardingHome {
  display: flex;
  height: fit-content;
  border-radius: 8px;
  overflow: hidden;
  padding-top: 1rem;
  text-align: center;
  flex-direction: column;
  background-color: var(--white);
}

.right_section_onboardingHome span {
  display: flex;
  gap: 1rem;
  align-items: center;
  text-align: center;
  flex-direction: row;
  margin-bottom: 1rem;
  margin-left: 2rem;
}

#description_right_section_onboardingHome {
  color: var(--text-grey);
}

.right_section_onboardingHome span p {
  color: #256fef;
  margin-bottom: 0;
}

.register_my_venue_btn_onBoarding {
  background-color: var(--primary-color);
  border: none;
  border-bottom-left-radius: 8px;
  color: var(--white);
  padding: 0.5rem;
  border-bottom-right-radius: 8px;
}

/* on boarding form css starts  */

.left_onboarding_form_wrapper img {
  width: 90%;
}

.left_onboarding_form_wrapper h1 {
  color: var(--primary-color);
}

.left_onboarding_form_wrapper {
  display: flex;
  margin-top: 10rem;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.progressBar_span_right_onboarding_form_wrapper {
  width: 100%;
  margin: auto;
}

.step_zero {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.step_zero span {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: space-between;
}

.step_zero input {
  box-shadow: none;
  outline: none;
  width: 100%;
  border-radius: 5px;
  padding: 0.3rem 0.3rem;
  border: 1px solid grey;
}

.step_zero .css-1fdsijx-ValueContainer-qbdosj-Input {
  margin-bottom: 1rem;
}

#green_verifyButton {
  background-color: var(--green);
  color: var(--white);
  box-shadow: none;
  text-decoration: none;
  outline: none;
  width: fit-content;
  border-radius: 5px;
  padding: 0.3rem 0.9rem;
  border: none;
}

.progress-bar {
  background-color: var(--primary-color);
}

.progress,
.progress-stacked {
  height: 0.5rem;
}

.back_btn_progressbar_wrapper {
  display: flex;
  margin-top: 2rem;
  margin-bottom: 1rem;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}

.back_btn_progressbar_wrapper a {
  border: 1px solid grey;
  padding: 0.2rem 0.5rem;
  border-radius: 50%;
  aspect-ratio: 1;
}

.card_container_onboarding {
  box-shadow: 2px 5px 18px 0px #0000002e;
}

.continue_skip_button_wrapper {
  display: flex;
  margin-top: 1rem;
  flex-direction: row;
  justify-content: space-between;
}

.continue_skip_button_wrapper a {
  text-decoration: none;
  border: none;
  color: var(--white);
  background-color: #256fef;
  padding: 0.2rem 0.6rem;
  border-radius: 5px;
}

#skip_btn_onboarding {
  background-color: var(--white);
  color: #256fef;
  border: 1px solid grey;
}

.checkBoxes {
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  padding-bottom: 2rem;
}

.checkBoxes h6 {
  margin: 0;
}

.checkBoxes span {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.checkBoxes span label {
  font-size: 13px;
  color: var(--text-grey);
}

.form_step3_first {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form_step3_first input {
  width: 100%;
  border-radius: 5px;
  border: 1px solid grey;
  padding: 0.3rem 0.3rem;
}

.spanCss {
  display: flex;

  gap: 1rem;

  input {
    width: 100%;
    border-radius: 5px;
    border: 1px solid grey;
    padding: 0.3rem 0.3rem;
  }
}

.checkBox_step4_onboarding {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 0.5rem;

  img {
    width: 16px;
    height: 16px;
  }

  label {
    font-family: "Roboto";
    font-size: 14px;
  }

  input {
    box-shadow: none !important;
    cursor: pointer;
  }
}

@media screen and (max-width: 991px) {
  .hideAfter991 {
    display: none !important;
  }

  .fixNextButton {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1rem;
    padding-left: 26px;
    background-color: var(--white);
  }
}

.fixNextButton {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  padding-left: 26px;
  padding-right: 50px;
  background-color: var(--white);
  display: flex;
  justify-content: flex-end;
  z-index: 999;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 500px) {
  .fixNextButton {
    padding-left: 19px;
  }
}

.formBgNew {
  background-color: var(--white);
  padding: 2rem 1rem;
  border-radius: 20px;
  box-shadow: 2px 5px 18px 0px #0000002e;
  margin-top: 1rem;
  margin: 0 1rem;
  margin-top: 1rem;
}

.belowbtnP {
  margin-left: 1rem;
  margin-bottom: 0;
}
