.image_gallery_section img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1.9;
  padding: 0.1rem;
}
.image_gallery_section .slider.animated {
  background-color: transparent;
}
.imagegallery_verticle_images {
  display: flex;
  flex-direction: column;
}

.view_more_image_wrapper {
  position: relative;
}
.reviews_black_section p {
  color: var(--primary-color);
}
.view_more_image_wrapper p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  padding: 0.2rem;
  border-radius: 5px;
  color: white;
  /* Change color as needed */
}

.wrapper_carousel img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

@media screen and (max-width: 576px) {
  .wrapper_carousel img {
    height: 300px;
  }
}

/* styling react tabs  */
.venue_about_section {
  margin-top: 4rem;
}

.tabs {
  width: 100%;
}

.tab-buttons {
  display: flex;
  margin-bottom: 10px;
}

.tab-buttons button {
  flex: 1;
  background: transparent;
  padding: 8px 1.5rem;
  cursor: pointer;
  border: none;
  border-bottom: 2px solid transparent;
}

.tab-buttons button.active {
  border-bottom: 2px solid var(--primary-color);
  color: var(--primary-color);
  font-weight: bold;
  outline: none;
  transition: 0.4s ease-in-out;
  background-color: #f0f0f0;
}

.about_venue_tabContent {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.about_venue_tabContent p {
  font-family: "Roboto";
  font-size: 14px;
  color: rgb(0 0 0);
}

.read-more-section p {
  display: inline-block;
}

.venue_features_section {
  margin-top: 2rem;
}

@media screen and (max-width: 576px) {
  .venue_features_section {
    margin-top: 0;
  }

  .venue_features_wrapper {
    gap: 0.6rem !important;
  }
}

.read-more-toggle:hover {
  text-decoration: underline;
}

.venue_features_wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  flex-direction: row;
}

.venue_features_wrapper p {
  margin: 0;
}

/* -----------------------------________Calendy------------------------------- */

.calenday_modelContainer {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  width: 100%;
  margin-top: -7px;
}

.calendy_modelHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(249, 250, 250, 1);
  padding: 0.5rem 1.5rem;
  color: var(--text-black);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.CloseCallnder {
  button {
    background-color: transparent;
    border: none;
  }
  img {
    height: 17px;
    width: 17px;
  }
}

.todays_booking {
  background: rgba(210, 232, 244, 1);
  color: var(--text-grey);
  width: fit-content;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 0rem 0.5rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.5rem;
  border-radius: 25px;
}

.calendy_modelHead p {
  margin: 0;
  color: var(--text-grey);
  font-family: "Roboto";
}

.calendy_modelHead h4 {
  margin: 0;
  font-weight: 600;
}

.calenday_modelSubHead {
  margin-top: 0.5rem !important;
  background: rgba(228, 228, 228, 1);
  margin: auto;
  text-align: center;
}

.calenday_modelSubHead p {
  margin: 0;
  color: var(--white);
  font-size: 14px;
}

.calenday_modelScreen {
  height: 380px;
  width: 100%;
  /* padding: 1rem 1rem; */
  display: flex;
}

.ocsnDopdown {
  width: 100%;
  height: 40px;
  background-color: var(--off-white);
  padding: 0.5rem 1rem;
  border-radius: 3px;
  color: var(--text-grey);
}

.p-dropdown-items {
  background-color: var(--off-white);
  width: 100%;
  color: var(--text-grey);
}

.p-dropdown-items li {
  padding: 0.3rem 0;
}

.MuiDateCalendar-root {
  width: 100% !important;
}

.calenderDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.css-1wy8uaa-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: var(--text-grey) !important;
}

.timeBox {
  border: 1px solid var(--primary-color);
  border-radius: 7px;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  min-height: 60px;
}

.timeBox:hover {
  background-color: var(--primary-color);
  color: var(--white);
}

.timeBox:hover p {
  color: var(--white);
}

.timeBox h6 {
  font-size: 15px;
  margin: 0;
  margin-bottom: 0.3rem;
}

.timeBox p {
  color: var(--text-grey);
  font-size: 12px;
  margin: 0;
}

.personBox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.personInfo {
  width: 100%;
}

.border0 {
  border-radius: 0 !important;
}

.personInfo
  .react-international-phone-input-container
  .react-international-phone-country-selector-button,
.personInfo
  .react-international-phone-input-container
  .react-international-phone-input {
  border-radius: 0 !important;
}

.personInfo .PhoneloginButton {
  width: 100%;
  border-radius: 0;
  height: 40px;
  margin-top: 1.5rem;
}

.sentOtp {
  color: var(--text-grey);
  font-size: 14px;
}

.sentOtp span {
  color: var(--primary-color);
}

.varifuy p {
  margin-bottom: 1.5rem;
}

.varifuy input {
  margin-bottom: 1.5rem;
}

.thankYou img {
  width: 3rem;
  margin-bottom: 1rem;
}

.thankYou {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.steps {
  background-color: var(--light-black);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 14px;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 0.2rem 0.3rem;
  flex-direction: row;
  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
  }
}

.black_section_carousel {
  border-bottom-left-radius: 19px;
  border-bottom-right-radius: 19px;
  padding: 0.8rem 1rem;
  font-family: "Roboto";
  color: var(--white);
  background-color: var(--secondary-black);
}

.black_section_carousel h6 {
  margin: 0;
}

.black_section_carousel p {
  font-size: 14px;
}

.black_section_carousel img {
  margin-left: -5px;
  width: 34px;
  height: 20px;
}

.first_row_black_section_carousel {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.french_text {
  border: 1px solid var(--white);
  padding: 0.2rem 0.8rem;
  border-radius: 50px 50px 50px 50px;
}

.reviews_black_section {
  margin-right: 0.5rem;
}

.fade-img {
  position: relative;
}

.view_photos {
  position: absolute;
  top: 0;
}

/* <img src= {
     view_photos
 }

 alt="view_photos"
 className="view_photos"
 /> */

.fade-img:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    var(--secondary-black) 100%
  );

  height: 40%;
}

.venuePage_venueCategory_heading img {
  width: 14px;
}

.venuePage_venueCategory_heading a,
.venuePage_venueCategory_heading span {
  font-size: 14px;
}

.menu_image_wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.see_all_buttonMenu {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  /* top: 0;
  left: 0; */
  width: calc(100% - 10px);
  height: 100%;
  border: none;
  border-radius: 12px;
  color: #ffffff;
  text-decoration: underline;
}

.menu_image_wrapper img {
  width: 100%;
  border-radius: 12px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.want_more_section {
  background-color: var(--secondary-black);
  color: var(--white);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  align-items: center;
  width: fit-content;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 0.5rem;
  width: 100%;
}

.want_more_section img {
  width: 25px;
  padding: 0.3rem;
  border: 1px solid var(--white);
  border-radius: 50%;
  aspect-ratio: 1;
  margin-left: 30px;
}

.want_more_section h6,
.want_more_section p {
  margin: 0;
}

.time_discount_container_detailedVenue {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 0.6rem !important;
  cursor: pointer;
  justify-content: center;
  padding: 0.1rem 0.2rem;
  overflow: auto;
  height: 338px;

  .discount_section p:hover {
    background-color: var(--secondary-black);
    color: var(--white);
  }
}

.time_discount_container_detailedVenue::-webkit-scrollbar {
  width: 10px;
}

.time_discount_container_detailedVenue::-webkit-scrollbar-track {
  background-color: var(--light-grey);
}

.time_discount_container_detailedVenue::-webkit-scrollbar-thumb {
  background-color: var(--light-black);
  border-radius: 10px;
}

.calendar_modal_heading {
  display: flex;
  justify-content: center;
  color: #535969;
  font-weight: 400;
}

.guests_calendar_modal {
  display: flex;
  gap: 0.3rem;
  justify-content: center;
  margin-bottom: 1rem;

  p {
    border: 1px solid grey;
    padding: 0.5rem 1.5rem;
    margin: 0;
    font-family: "Roboto";
    font-size: 14px;
    border-radius: 5px;
  }

  p:hover {
    background-color: var(--light-pink);
    cursor: pointer;
  }
}

.wrapper_calendar_modal {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: left;
  display: flex;
  flex-direction: column;
  padding-right: 0.8rem;

  input {
    width: 100%;
    box-shadow: none;
    outline: none;
    padding: 0.4rem 1rem;
    border-radius: 5px;
    border: 1px solid grey;
    margin-bottom: 0.5rem;
  }

  a {
    margin-top: 0.8rem !important;
    display: flex;
    justify-content: center !important;
    background-color: var(--primary-color);
    text-decoration: none;
    color: var(--white);
    padding: 0.4rem 1rem;
    border-radius: 5px;
  }
}

.wrapper_calendar_modal a:hover {
  background-color: var(--primary-colorHover);
}
.closeIconCalnder {
  cursor: pointer;
}
.ImgGllry img {
  border-radius: 10px;
}

.details_step_calendar_modal {
  display: flex;
  font-size: 14px;
  flex-direction: column;
  gap: 0.5rem;
  color: var(--text-grey);

  span {
    /* margin-left: 3px; */
    display: flex;
    justify-content: left;
    text-align: left;
    height: fit-content;
    /* align-items: center; */
  }

  input {
    background: #ededed;
    border: none;
    padding: 0.4rem 1rem;
    margin-top: 0.5rem;
  }

  h6 {
    margin: 0;
    padding-right: 1rem;
  }

  p {
    margin: 0;
  }

  label {
    /* margin-left: 1rem; */
    font-family: "Roboto";
    font-size: 14px;

    align-items: center;
  }

  .checkBoxUnique {
    height: 10px;
    width: 10px;
    /* margin: 0 11px 1px 10px; */
  }
}
.FindTableHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    height: 14px;
    width: 14px;
  }
}
.HighLightPolicy {
  cursor: pointer;
  color: var(--primary-color);
}
.HighLightPolicy:hover {
  color: var(--primary-colorHover);
}

.final_step_wrapper {
  background: #edebeb;

  margin: auto;
  text-align: center;

  p {
    background-color: var(--white);
    color: var(--text-black);
    padding: 0.4rem 1.5rem;
    border-radius: 5px;
  }

  span {
    margin: auto;
  }
}

.calenday_modelScreen.greyBackground {
  background: #edebeb;
  display: flex;
}

.confirmed_booking_span {
  margin-top: auto;
  margin-bottom: auto;

  h6 {
    color: var(--green);
    font-weight: 600;
  }

  desc {
    font-size: 14px;
    font-weight: 500;
  }
}

.css-23p0if-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: var(--light-black) !important;
}

.rhombus {
  height: 100%;
  aspect-ratio: 1;
  clip-path: polygon(0 0, 0 50%, 0 100%, 50% 50%);
  background: var(--light-black);
  width: 38px;
  margin-left: -1px;
}

.square {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  background: var(--light-black);
  padding: 0.2rem;
}

.rightArrowWhite {
  width: 10px !important;
}

.resrvDateSelect ul {
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0;
  width: 100%;
}

.resrvDateSelect,
.resrvDateSelect ul li {
  width: 100%;
  margin-bottom: 0.3rem;
}

.resrvDateSelect ul li p,
.addInputBtn {
  text-align: center !important;
  margin: 0;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  aspect-ratio: 1.5;
  border: 1px solid grey;
  border-radius: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.selectedFormItems {
  background-color: #ffe9eb !important;
  border-radius: 5px;
  color: #535969 !important;
}

.selectedFormItems p {
  color: var(--white-text) !important;
}

.hideInput {
  display: none;
}

.resrvDateSelect {
  position: relative;
}

.addInputBtn {
  /* display: flex;
  margin-left: 1px;
  justify-content: center;
  width: 50px;
  height: 50px;
  position: relative;
  right: 1px;
  top: 8px;
  padding: 1rem 2.6rem; */
  /* width: 1.5rem; */
  /* height: 1.5rem; */
  cursor: pointer;
}

.addInputBtn img {
  width: 20px;
  height: 20px;
  /* margin-top: 5px; */

  height: 14px;
}

.customRsrvInput input {
  outline: none;
  border-radius: 5px;
  padding: 0.9rem 1rem;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  margin: 0;
  height: 100%;
}

.customRsrvInput {
  height: 100%;
}

.parentContainer {
  position: relative;
  display: flex;
}

.sticky-container {
  position: sticky;
  top: 96px;
  align-self: flex-start;
}

.block_table {
  background-color: #eee !important;
  color: #666666 !important;
}

.block_table span {
  color: #666666 !important;
}

.block_table:hover {
  background-color: #eee !important;
  color: #666666 !important;
  cursor: not-allowed;
}

.EquiryButtonMobile {
  display: none;
  width: 100%;
  justify-content: center;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 0;
  background-color: white;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.EquiryButtonMobile button {
  width: fit-content;
  padding: 0.4rem 1rem;
  border: none;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: var(--white);
}

.EquiryButtonMobile button {
  width: 50%;
  padding: 0.7rem;
  margin-bottom: 3px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
}

.calenday_modelContainermobile {
  z-index: 100;
  min-width: 100%;
  left: 0;
  background-color: white;
  position: fixed;
  bottom: -80rem;
  transition: bottom 0.4s ease;

  width: 100%;

  overflow: hidden;
  padding: 0.6rem;
}

.calenday_modelContainermobile.show {
  bottom: 0;
  padding-bottom: 1rem;
}
.OpenFOrm {
  margin-top: 0.5rem;
}
@media (min-width: 590px) and (max-width: 992px) {
  .calenday_modelContainermobile {
    padding-top: 1.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .calenday_modelContainer {
    width: 450px;
  }
}
@media (max-width: 589px) {
  .calenday_modelContainer {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .EquiryButtonMobile {
    display: flex;
  }

  .want_more_section {
    display: none;
  }

  .venuePage_venueCategory_heading {
    display: none;
  }
  .calendy_modelHead h4 {
    padding-top: 0.5rem;
  }

  .details_step_calendar_modal {
    span {
      align-items: center;
    }

    .checkBoxUnique {
      margin: 0 11px 1px 10px;
    }
  }
}

.MobileCrossButton {
  z-index: 9999;
  display: none;
  position: absolute;
  top: 7px;
  right: 22px;
}

.MobileCrossButton button {
  background-color: transparent;
}

.last_line_black_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media (max-width: 500px) {
  .last_line_black_section {
    display: flex;
    flex-direction: column;
    margin-bottom: 0 !important;

    .first_row_black_section_carousel {
      justify-content: left;
      margin-top: 0.5rem;
    }
  }
}

.center-allign {
  align-content: center;
}

.padding_no_show_msg {
  padding: 15px;
  background-color: #eee !important;
  color: #666666 !important;
}

.display_contents {
  display: contents;
  width: 100%;
}

.display_contents img {
  width: 15px;
}

.stepBg {
  background-color: #edebeb;
  height: 100%;
  width: 100%;
  padding: 1rem 1rem;
  display: flex;
  align-items: center;
}

.divContainerBook {
  padding: 0.5rem;
  background-color: #ffffff;
}

.backgrwh {
  background-color: #e4e4e4;
  border-radius: 8px;
  height: 35px;
}

.containerSteps {
  display: flex;
  align-items: center;
  width: 100%;
}

.firstStep {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding-left: 1.5rem;
}

.lastStep {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-right: 1.5rem;
}

.time_section2 p {
  border: 0.2px solid #535969;
  border-radius: 5px;
  height: 36px;
  width: 58px;
  font-family: "Roboto";
  margin: 0;
  font-size: 15px;
  background-color: #ffffff;
  color: #535969;
  display: flex;
  align-items: center;
  justify-content: center;
}

.time_section2 p:hover {
  background-color: #ffe9eb;
}

.time_discount_container_detailedVenue2 {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 0.6rem !important;
  cursor: pointer;
  justify-content: start;
  padding: 0.1rem 0.2rem;
  overflow: auto;
}

.time_discount_section {
  position: relative;
  margin-bottom: 1rem;
}

.discount_section2 p {
  border: 1px solid var(--secondary-black);
  padding: 0.1rem 0.7rem;
  background-color: #535969;
  z-index: 9;
  border-radius: 4px;
  font-family: "Roboto";
  font-size: 9px;
  width: 30px;
  height: 19px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 28px;
  right: auto;
  left: 14px;
}

.contianerTimings {
  overflow: auto;
  padding: 0.5rem 1rem;
  width: 100%;
}

@media screen and (max-width: 576px) {
  .p0a576 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .padding5a576 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

@media screen and (max-width: 400px) {
  .venue_features_wrapper {
    gap: 0.2rem !important;
  }
}

@media screen and (min-width: 992px) {
  .CloseCallnder img {
    display: none;
  }
  .calnderSHowButton {
    display: none;
  }
}
/* @media screen and (max-width: 992px) {
  .calnderSHowButton {
    display: none;
  }
} */
