/* browse cities */
.browse_otherCities_section {
  margin-top: 1rem;
}

.browse_otherCities_heading h3 {
  font-family: "Roboto";
  font-weight: 600;
  color: rgba(42, 45, 50, 1);
}

.city-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.city-card {
  padding: 10px;
  max-width: 200px;
  text-align: center;
}

.city-description {
  margin-top: 10px;
  text-align: center;
  font-family: "Roboto";
  font-weight: 500;
  color: var(--text-black);
  font-size: 16px;
}

.slick-prev:before,
.slick-next:before {
  color: var(--primary-color) !important;
  font-size: 30px !important;
  background: transparent !important;
}

.slick-next {
  right: -16px !important;
}

.slick-prev,
.slick-next {
  top: 45% !important;
  width: 30px !important;
  height: 30px !important;
  aspect-ratio: 1;
}

.cities_mapped {
  margin: auto;
}

.city-item {
  position: relative;
  width: fit-content;
  height: fit-content;
  border-radius: 10px;
}

.city-image {
  cursor: pointer;
  width: 200px;
  height: 200px;
  aspect-ratio: 1;
  border-radius: 10px;
  position: relative;
}
@media (max-width: 480px) {
  .city-image {
    cursor: pointer;
    width: 158px;
    height: 169px;}
}

.city-image::before {
  content: "";
  background-image: url("../../Assets/ovrerlayBlack.png");
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.city_description {
  position: absolute;
  bottom: 10px;
  left: 20px;
  cursor: pointer;

  h6 {
    margin: 0;
    color: var(--white);
  }

  p {
    margin: 0;
    color: var(--primary-color);
  }

  span {
    gap: 0.2rem;
  }
}

.scroll-container {
  gap: 6px;
  width: 100%;
  height: 100%;

  display: flex;
  overflow-x: auto;
  white-space: nowrap;

  padding: 10px 0;
}

.slick-slider {
  width: 100%;
}

.slick-slide img {
  width: 100%;
  height: 100%;
  /* padding: 0rem 0.4rem; */
}

#right_arrow_small {
  width: 5px;
  margin-left: 5px;
  margin-bottom: 0;
  margin-top: 6px;
  padding: 0 !important;
}

.city-item {
  padding: 0.1rem;
}

.collection_card {
  display: grid;
  place-items: center;
  width: 100%;
  max-width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 0.625rem;
  /* box-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.25); */
  color: var(--white);
  padding: 0;
}

.collection_card h2 {
  font-size: 15px;
  font-family: var(--font-title);
  color: var(--white);
  line-height: 1.1;
}

.collection_card p {
  font-family: var(--font-text);
  font-size: 13px;
  line-height: 1.5;
  color: var(--white);
  margin-top: 20px;
  display: flex;
}

.city-item a {
  text-decoration: none !important;
}

.collection_card > * {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  color: transparent;
}

.collection_card__background {
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}

.collection_card__content {
  --flow-space: 0.9375rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: flex-end;
  height: 55%;
  padding: 12% 1.25rem 1.875rem;
  /* background: linear-gradient(180deg,
      hsla(0, 0%, 0%, 0) 0%,
      hsla(0, 0%, 0%, 0.3) 10%,
      hsl(0, 0%, 0%) 100%); */
  width: 100%;
}

.collection_card__content--container {
  --flow-space: 1.25rem;
}

.collection_card__title {
  position: relative;
  width: fit-content;
  width: -moz-fit-content;
  /* Prefijo necesario para Firefox  */
}

.collection_card__title::after {
  content: "";
  position: absolute;
  height: 0.3125rem;
  width: calc(100% + 1.25rem);
  bottom: calc((1.25rem - 0.5rem) * -1);
  left: -1.25rem;
  background-color: var(--brand-color);
}

.collection_card__button {
  padding: 0.75em 1.6em;
  width: fit-content;
  width: -moz-fit-content;
  /* Prefijo necesario para Firefox  */
  font-variant: small-caps;
  font-weight: bold;
  border-radius: 0.45em;
  border: none;
  background-color: var(--brand-color);
  font-family: var(--font-title);
  font-size: 1.125rem;
  color: var(--black);
}

.collection_card__button:focus {
  /* outline: 2px solid black; */
  outline-offset: -5px;
}

@media (any-hover: hover) and (any-pointer: fine) {
  .collection_card__content {
    transform: translateY(50%);
    transition: transform 200ms ease-out;
    transition-delay: 200ms;
  }

  .collection_card__title::after {
    opacity: 0;
    transform: scaleX(0);
    transition: opacity 200ms ease-in, transform 200ms ease-out;
    transition-delay: 200ms;
    transform-origin: right;
  }

  .collection_card__background {
    transition: transform 200ms ease-in;
  }

  .collection_card__content--container > :not(.collection_card__title),
  .collection_card__button {
    opacity: 0;
    transition: transform 200ms ease-out, opacity 200ms ease-out;
  }

  .collection_card:hover,
  .collection_card:focus-within {
    /* transform: scale(1.05); */
    transition: transform 200ms ease-in;
  }

  .collection_card:hover .collection_card__content,
  .collection_card:focus-within .collection_card__content {
    transform: translateY(0);
    transition: transform 200ms ease-in;
  }

  .collection_card:focus-within .collection_card__content {
    transition-duration: 0ms;
  }

  .collection_card:hover .collection_card__background,
  .collection_card:focus-within .collection_card__background {
    /* transform: scale(1.3); */
  }

  .collection_card:hover
    .collection_card__content--container
    > :not(.collection_card__title),
  .collection_card:hover .collection_collection_card__button,
  .collection_card:focus-within
    .collection_card__content--container
    > :not(.collection_card__title),
  .collection_card:focus-within .collection_card__button {
    opacity: 1;
    transition: opacity 200ms ease-in;
    transition-delay: 200ms;
  }

  .collection_card:hover .collection_card__title::after,
  .collection_card:focus-within .collection_card__title::after {
    opacity: 1;
    transform: scaleX(1);
    transform-origin: left;
    transition: opacity 200ms ease-in, transform 200ms ease-in;
    transition-delay: 200ms;
  }
}

.cuisine_card {
  overflow: visible;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.cuisine_content {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 300ms;
  /* box-shadow: 0px 0px 3px 1px #000000ee; */
  border-radius: 5px;
  border-radius: 50%;
}

.cuisine_back {
  /* background-color: #151515; */
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  border-radius: 5px;
  overflow: hidden;
  border-radius: 50%;
}

.cuisine_back {
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
}

.cuisine_back::before {
  position: absolute;
  content: " ";
  display: block;
  width: 100px;
  height: 100%;
  /* background: linear-gradient(90deg, transparent, var(--primary-color), var(--primary-color), var(--primary-color), var(--primary-color), transparent); */
  animation: rotation_481 5000ms infinite linear;
  border-radius: 50%;
}

.cuisine_back-content {
  position: absolute;
  width: 105%;
  height: 104%;
  top: 1px;
  left: -2px;
  /* background-color: #151515; */
  border-radius: 5px;
  color: white;
  display: flex;
  /* flex-direction: column;
  justify-content: center;
  align-items: center; */
  gap: 60px;
  border-radius: 50%;
}

@keyframes rotation_481 {
  0% {
    transform: rotateZ(0deg);
  }

  0% {
    transform: rotateZ(360deg);
  }
}

.image_set_cuisine {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.city_description2 {
  cursor: pointer;

  h6 {
    margin: 0;
    color: var(--black);
    text-align: -webkit-center;
    margin-top: 10px;
  }

  p {
    margin: 0;
    color: var(--primary-color);
  }

  span {
    gap: 0.2rem;
  }
}

.city-item2 {
  text-align: -webkit-center;
}

.color_red {
  color: var(--primary-color) !important;
}
@media only screen and (max-width: 376px) {
  .city-image {
    cursor: pointer;
    width: 145px;
    height: 156px;
  }
}