.footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  /* border-top: 2px solid #ddd; */
  z-index: 1000;
  box-shadow: 0px -1px 2px 0px #00000040;
}

.footer-item span {
  font-family: Roboto;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0.21051837503910065px;
  text-align: center;
}

.footer-item {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: gray;
  cursor: pointer;
  transition: color 0.3s ease;
  position: relative;
  background-color: white;
  padding: 10px;
}

.footer-item i {
  font-size: 20px;
  color: gray;
}

.footer-item.active {
  color: red;
}

.footer-item.active i {
  color: #e33745;
}

.footer-item.active::before {
  content: "";
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 60%;
  height: 25px;
  background-color: #ffffff;
  border-top-left-radius: 350px;
  border-top-right-radius: 350px;
  border-bottom: none;
  box-shadow: 0px -1px 2px 0px #00000040;
  z-index: -17;
}

.footer-item span {
  margin-top: 5px;
  color: gray;
}

@media (min-width: 769px) {
  .mobile {
    display: none;
  }
}
@media (max-width: 1024px) and (min-width: 450px) {
  .footer-item.active::before {
    width: 30%;
  }
}
