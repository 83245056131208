#homeBG {
  width: 100%;
  height: 50vh;
  border-radius: 20px;
  position: relative;
  object-fit: cover;
}
.ovrFLowLocation {
  margin-top: 1rem;
  margin-bottom: 3rem;
}
.populerLocalitySect {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.Locationarddds {
  cursor: pointer;
  margin-bottom: 0.8rem;
  margin-right: 10px;
  border: 1px solid #e3e3e3;
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  h5 {
    font-size: 15px;
  }
  h3 {
    margin-left: 1px;
    font-size: 15px;
  }
  p {
    padding-top: 0.5rem;
    font-size: 14px;
    font-weight: 300;
    color: #696969;
    margin: 0;
  }
  img {
    height: 13px;
    width: 13px;
  }
}
.ArrowImgD img {
  margin-top: 4px;
  height: 13px !important;
  width: 13px !important;
}
.Locationarddds:hover {
  box-shadow: 0px 0px 3.76px 0px rgba(0, 0, 0, 0.25);
}
.marginBttm {
  margin-bottom: 2rem;
}

@media screen and (max-width: 1400px) {
  #homeBG {
    height: 70vh;
  }
}
@media screen and (max-width: 991px) {
  .widthPolirClass {
    width: 15%;
  }
}
@media screen and (max-width: 722px) {
  .widthPolirClass {
    width: 20%;
  }
}
@media screen and (min-width: 1000px) {
  .widthPolirClass {
    width: 11.2%;
  }
}

@media screen and (max-width: 576px) {
  #homeBG {
    height: 45vh;
  }
  .Locationarddds {
    margin-bottom: 1rem;
  }
  .venue_category_div {
    padding-top: 5px;
  }
  .populerlocationNEwDesign {
    .PopLodata {
      min-width: 120px;
      p {
        padding-top: 0.5rem;
        font-size: 11px;
      }
      label {
        font-size: 10px;
      }
    }
  }

  .widthPolirClass {
    width: 19%;
  }
}
@media screen and (max-width: 546px) {
  .widthPolirClass {
    width: auto;
  }
}

.heroSection {
  margin-top: 2rem;
}

.heroSection_wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.searchBar_container_homeScreen {
  position: absolute;
  width: 70%;
}

.searchBarInHeroSection {
  width: 100%;
  height: 100%;
  position: relative;
  margin: auto;
}

.Heading_herosection {
  padding: 0.5rem 0.5rem;
  width: 100%;
  flex-wrap: wrap;
}

@media screen and (max-width: 1200px) {
  .Heading_herosection h1 {
    font-size: 30px;
  }

  .searchBar_container_homeScreen {
    position: absolute;
    width: 80%;
  }
}

@media screen and (max-width: 991px) {
  .Heading_herosection h1 {
    font-size: 30px;
  }

  .searchBar_container_homeScreen {
    position: absolute;
    width: 90%;
    margin: auto;
  }
}
@media (min-width: 769px) {
  .collections_section {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .Heading_herosection h1 {
    font-size: 24px;
  }
}

@media screen and (max-width: 576px) {
  .Heading_herosection h1 {
    font-size: 20px;
  }
}

.collections_section {
  margin-top: 2rem;
}

.slick-next {
  right: 16px !important;
}

.slick-prev {
  z-index: 90;
  left: -16px !important;
}

/* popular venues section css */
.popularVenues_section {
  margin-top: 3rem;
}

.popularVenues_venue_container {
  padding: 0.6rem 0.6rem;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  font-family: "Roboto";
  transition: ease-in 0.1s;
  box-shadow: 0px 0px 3.76px 0px rgba(0, 0, 0, 0.25);
}

.popularVenues_venue_container:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.slick-prev:before,
.slick-next:before {
  line-height: 0;
}

.popularVenues_heading_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.popularVenues_heading_container h3 {
  color: rgba(42, 45, 50, 1);
  font-weight: 600;
}

.popularVenues_heading_container a {
  text-decoration: none;
  color: var(--text-grey);
  font-family: "Roboto";
  font-size: 14px;
}

.popularVenues_heading_container a:hover {
  text-decoration: underline;
  color: var(--primary-color);
}

.venue_image_holder img {
  width: 100%;
  aspect-ratio: 1.3;
  cursor: default;
  transition: 0.3s ease-in-out;
  border-radius: 5px;
  cursor: pointer;
}

/* .venue_image_holder img:hover {
  transform: scale(0.97);
} */

.venue_image_holder {
  position: relative;
}

.heart_icon {
  position: absolute;
  /* Position the heart icon absolutely */
  top: 10px;
  /* Adjust distance from the top */
  right: 10px;
  /* Adjust distance from the right */
  z-index: 10;
  /* Ensure it's above other content */
  width: 30px;
  /* background-color: var(--secondary-black); */
  padding: 0.2rem 0.3rem;
  border-radius: 48%;
  aspect-ratio: 1;
}

.venue_category_titles {
  display: flex;
  flex-direction: row;
  color: var(--secondary-black);
  font-weight: 600;
  gap: 0.2rem;
  margin-bottom: 0.5rem;
  font-size: 14px;
  align-items: center;
  justify-content: center;
}

.venue_category_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.venue_category_div p {
  margin-bottom: 0;
}

.rating_greenDiv {
  height: fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  border-radius: 4px;
  padding: 0.1rem 0.3rem;
  flex-direction: row;
  color: var(--white);
  font-size: 12px;
  font-family: "Roboto";
  font-weight: 700;
  background-color: var(--green);
}

.rating_greenDiv p {
  color: var(--white);
}

.rating_greenDiv img {
  height: 15px;
  width: 15px;
}

.venue_capacity_wrapper {
  margin-top: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5rem;
}

.venue_capacity_wrapper p {
  color: var(--text-black);
  margin: 0;
  font-size: 14px;
}

.venue_address_wrapper p {
  font-size: 14px !important;
  color: var(--text-grey);
}

.venue_address_wrapper desc {
  margin-bottom: 0.2rem;
  color: var(--text-black);
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
}

.address_restaurant_home {
  font-size: 14px;
}

.venue_address_wrapper h6 {
  font-size: 16px;
  color: var(--text-black);
  font-weight: 600;
}

.venue_cards_container {
  display: flex;
  flex-wrap: wrap;
}

.pagination_controls {
  display: flex;
  flex-direction: row;
  gap: 0.6rem;
}

.pagination_controls a {
  white-space: nowrap;
}

.pagination_controls button {
  background-color: var(--white);
  border: 1px solid rgba(236, 237, 239, 1);
  border-radius: 4px;
  aspect-ratio: 1;
  cursor: pointer;
  width: 40px;
}

.pagination_controls button:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.seAll_span {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.seAll_span p {
  margin: 0;
  color: var(--primary-color);
}

.venue_discount_wrapper p {
  margin-top: 1rem;
  margin-bottom: 0;
  font-weight: 600;
  width: fit-content;
  color: var(--white);
  border-radius: 5px;
  padding: 0.2rem 0.7rem;
  font-size: 12px;
  background: var(--Secondary-Color-Black, rgba(42, 45, 50, 1));
}

.how_it_works_content {
  padding: 3rem 3rem;
  align-items: center;
  border-radius: 16px;
  margin-left: auto;
  margin-right: auto;
  background: var(--Secondary-Color-Black, rgba(42, 45, 50, 1));
}

.how_does_it_work_section {
  margin-top: 4rem;
}

.how_it_works_content_heading {
  display: flex;
  margin-bottom: 2rem;
  font-family: "Roboto";
  justify-content: center;
  color: var(--Primary-Color, rgba(243, 120, 53, 1));
}

.how_it_works_content {
  padding-bottom: 3rem;
}
@media (max-width: 540px) {
  .cuisine_back-content img {
    width: 90%;
    height: 90%;
  }
  .venue_image_holder img {
    width: 100%;
    height: 150px;
  }
}

.how_it_works_content h6,
.how_it_works_content p {
  margin-bottom: 0;
  color: var(--white);
}

.how_it_works_content p {
  font-size: 14px;
}

.how_it_works_content img {
  width: 60px;
  color: var(--white);
  margin-bottom: 0.5rem;
}

#multiStars {
  width: 110px;
  margin-bottom: 2rem;
}

/* discover more section  */
.discover_more_venues_section {
  margin-top: 4rem;
}

.discoverMore_container {
  /* background-color: var(--light-pink); */
  border-radius: 10px;
}

.discoverMore_container:hover img {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.discoverMore_containerText {
  padding: 0.5rem 1.2rem;
}

.discoverMore_container img {
  height: 217.1px;
  width: 100%;
  margin-bottom: 0.4rem;
  border-radius: 9px;
}

.discoverMore_container p {
  font-size: 14px;
  color: var(--text-black);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.discoverMore_container h6 {
  padding-top: 0.5rem;
  font-size: 21px;
  margin-bottom: 0.5rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.discover_more_venues_heading h3 {
  font-family: "Roboto";
  font-weight: 600;
  color: var(--text-black);
}

.verticle_container_discoverMore {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.smaller_container_discoverMore {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.smaller_container_discoverMore:hover img {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.smaller_container_discoverMore p {
  margin: 0;
}

.smaller_container_discoverMore hr {
  margin: 0;
}

.smaller_container_discoverMore img {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  object-fit: cover;
}

.heading_discoverMore {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-bottom: 1px solid var(--border-color);
  gap: 0.5rem;
  width: 100%;
}

.heading_discoverMore h6 {
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}

.heading_discoverMore p {
  color: var(--primary-color);
  font-size: 14px;
}

.are_you_a_venue_owner {
  display: flex;
  text-align: center;
  flex-direction: column;
  font-family: "Roboto";
  justify-content: center;
}

.are_you_a_venue_owner h6,
.are_you_a_venue_owner p {
  color: var(--text-grey);
}

.are_you_a_venue_owner a {
  color: var(--white);
  border: none;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  background: rgba(227, 55, 69, 1);
  border-radius: 5px;
  padding: 0.4rem 1rem;
  text-decoration: none;
  font-family: "Roboto";
  font-size: 14px;
  transition: all ease 0.1s;
}

.are_you_a_venue_owner a:hover {
  background-color: var(--primary-colorHover);
}

.areyoua_venue_owner_section {
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.HeartHomeIcon {
  cursor: pointer !important;
  position: absolute;
  top: 10px;
  right: 10px;
  height: 22px !important;
  width: 22px !important;
}

.heartBttnn {
  border: none;
  background-color: transparent;
  img {
    border-radius: 50%;
    height: 24px !important;
    width: 24px !important;
    padding: 4px;
    background-color: var(--white);
    transition: transform 0.5s ease;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }
  img:hover {
    transform: scale(1.1);
  }
}

@media (max-width: 420px) {
  .how_it_works_content {
    padding: 1rem 0rem;
    gap: 1rem;
    text-align: center;
    justify-content: center;
  }
}

/* stykleendjnejkdnajkdnjan */
.horizontal-scroll-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px 0;
  -webkit-overflow-scrolling: touch;
  gap: 0.5rem;
  /* padding-left: 0.5rem; */
}

.horizontal-scroll-container::-webkit-scrollbar {
  display: none;
}

.collections_sections_heading h6 {
  font-weight: 400;
}

.collections_sections_heading h3 {
  font-weight: 600;
  color: var(--secondary-black) !important;
}

.padding5p {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.box_min {
  min-width: 258px;
}

@media screen and (max-width: 576px) {
  .padding991 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}

.padding0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media screen and (max-width: 768px) {
  .heroSection {
    position: static;
    top: 0px;
    padding: 0.5rem 0;
    z-index: 999;
    background-color: #ffffff;
  }
}
.imageblog_size {
  width: 100%; /* Or a specific width */
  height: auto; /* Maintains aspect ratio */
  object-fit: cover; /* Adjusts how the image fits the container */
}
.Local {
  padding: 0px;
}
/* Praveen home page  */
@media only screen and (max-width: 376px) {
  .city-image {
    width: 150px;
    height: 150px;
  }
  .popularVenues_venue_container {
    height: 330px;
    width: 200px;
  }
  .venue_image_holder img {
    height: 130px;
  }
  .popularVenues_venue_container {
    height: 310px;
  }
}
@media (max-width: 576px) {
  .popularVenues_venue_container {
    padding: 1px;
    height: 330px;
    width: 250px;
  }
  .scroll-container {
    gap: 5px;
  }
  .horizontal-scroll-container {
    gap: 16px;
  }
  .mobilePddng {
    padding: 0;
  }
  .venue_address_wrapper,
  .venue_category_div {
    padding-left: 7px;
  }
}
@media (max-width: 991px) {
  .how_it_works_content {
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 0.3rem;
  }

  .how_it_works_content img {
    width: 60px;
    color: var(--white);
    margin-bottom: 0.5rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.5rem;
  }

  .seAll_span p {
    display: none;
  }

  .seAll_span {
    margin-bottom: 0.5rem;
    align-items: flex-end;
  }

  .popularVenues_heading_container {
    align-items: flex-end;
  }

  .padding991 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

/*---------------------popular  location new design-----------------*/
.populerlocationNEwDesign {
  display: flex;

  width: 100%;
  overflow: auto;
  padding: 0.5rem 0rem;
  scrollbar-width: none;
  -ms-overflow-style: none;
  img {
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    height: 80px;
    width: 80px;
  }
  span {
    padding: 0.2rem;
    border-radius: 50%;
    border: 2px solid var(--primary-color);
  }
  .PopLodata {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 0.8rem;
  }
  p {
    font-weight: 500;
    padding-top: 0.7rem;
    font-size: 13px;
    cursor: text;
    margin: 0;
  }
  label {
    font-size: 11px;
    cursor: text;
    margin: 0;
    color: var(--text-grey);
  }
}
.populerlocationNEwDesign::-webkit-scrollbar {
  display: none;
}

/* -------------------------------------- comming soon--------------------------------------- */

.comming_soon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 100px);
  background-color: var(--light-blue);
}

.comming_soon_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.comming_soon_wrapper div {
  width: fit-content;
  text-align: center;
}

.commingImg {
  width: 40%;
}

.comingHead {
  font-size: 56px;
  margin-top: 1.5rem;
}
