.leftSection img {
  width: 100%;
  height: 100%;
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
}

.playStore_section {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  gap: 0.4rem;
  font-family: "Roboto";
  font-weight: 600;
  margin-right: auto;
  justify-content: center;
}

.rightSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
}

.playStore_section img {
  width: 20%;
  cursor: pointer;
}

.download_now_section {
  margin-top: 2rem;
}
.mxWidthClss {
  max-width: 80% !important;
  font-size: 14px !important;
  text-align: center;
  margin-bottom: 2rem !important;
}

@media (max-width: 556px) {
  .leftSection img {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .rightSection {
    text-align: center;
    padding: 1rem 2rem;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }
  .MobileViewPd {
    padding: 0;
  }
}
