.footer_section {
  background-color: #f0f0f0;
  margin-top: 4rem;
}

.left_section_footer {
  margin-top: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.left_section_footer img {
  width: 150px;
  margin-bottom: 1rem;
}

.left_section_footer p {
  font-size: 14px;
  margin-bottom: 0;
  color: #000000;
  font-weight: 400;
}

.footer_about_my_venue {
  padding: 0.5rem 0;
}

.footer_about_my_venue p {
  margin-bottom: 0.5rem;
}

.footer_learn_more {
  margin-bottom: 20px;
  padding: 0.5rem 0;
}

.footer_learn_more ul,
.footer_about_my_venue ul {
  padding-left: 0rem;
  list-style-type: none;
}

.footer_learn_more ul li,
.footer_about_my_venue ul li {
  font-size: 14px;
  font-family: "Roboto";
  cursor: pointer;
  color: var(--text-grey);
  text-decoration: none !important;
  margin-bottom: 0.5rem;
}

.footer_learn_more ul li a,
.footer_about_my_venue ul li a {
  color: var(--text-grey);
  text-decoration: none !important;
}

.footer_learn_more ul li a:hover,
.footer_about_my_venue ul li a:hover {
  color: var(--primary-color) !important;
}

.footer_learn_more p {
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.row_footer {
  padding: 0.5rem 0.5rem;
  justify-content: left;
  display: flex;
}

.heading_text_footerlinks {
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 0;
  font-weight: 700 !important;
}

.social_links {
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
}

.social_links a {
  transition: 1s ease-in-out;
}

.social_links img {
  width: 33px;
  height: 33px;
  transition: 0.2s ease-in-out;
}

.social_links img:hover {
  transform: scale(1.2);
}
.post_footer_text {
  padding-top: 1.2rem;
  /* border-top: 1px solid #d9dada; */
}
.post_footer_text p {
  /* margin-left: -105px; */
  text-align: left;
  color: var(--text-grey);
  font-size: 14px;
}

.third_row_selectLanguage_footer {
  margin-top: -45px;
  display: flex;
  flex-direction: row;
  width: fit-content;
  padding: 0.2rem 0.4rem;
  border: 1px solid grey;
  border-radius: 5px;
  margin-bottom: 1rem;
}

.third_row_selectLanguage_footer select {
  border: none;
  outline: none;
  background-color: transparent;
}

.social_links_app_text p {
  margin-top: -15px;
  color: var(--text-grey);
  font-size: 14px;
}

.social_links_app_text p:hover {
  color: var(--primary-color);
}
.borddrBottm {
  border-bottom: 1px solid #d3d4d4;
}

@media screen and (max-width: 991px) {
  .footer_section {
    padding: 1rem 2rem;
  }
}

.brandingFooter img {
  margin-left: 20px;
  padding-top: 30px;
  width: 150px;
  margin-bottom: 1rem;
}

@media screen and (max-width: 768px) {
  .third_row_selectLanguage_footer {
    margin-top: 0px;
  }
  .footer_section {
    min-height: calc(600px + 50px);
  }
}

@media screen and (max-width: 576px) {
  .footer_section {
    padding: 1rem 0.5rem;
  }
  .footer_section {
    min-height: calc(650px + 190px);
  }
}
