#homeBG {
  width: 100%;
  height: 50vh;
  border-radius: 20px;
  background-size: cover;
}

.navbar-toggler {
  box-shadow: none !important;
  border: none;
}

.heroSection_wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.searchBar_container_homeScreen {
  position: absolute;
  width: fit-content;
}

.searchBarInHeroSection {
  width: fit-content;
  margin: auto;
}

.Heading_herosection {
  padding: 0.5rem 0.5rem;
  width: 100%;
  flex-wrap: wrap;
}

.Heading_herosection h1 {
  display: flex;
  justify-content: center;
  text-align: center;
  color: var(--white);
  font-family: "Roboto";
  font-weight: 600;
}

/* modal css */
.modal-header {
  border-bottom: none;
  padding-bottom: 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.phoneLoginModal_body {
  min-height: 300px;
  display: flex;
  padding: 1.5rem 2.5rem;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  gap: 0.4rem;
}

.phoneLoginModal_body p {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  color: var(--text-grey);
}

.phoneLoginModal_body h6 {
  font-family: "Roboto";
}

.phoneLoginModal_body input {
  border-radius: 4px;
  width: 100%;
  padding: 0.3rem 1rem;
  outline: none;
}

.PhoneloginButton {
  background-color: var(--primary-color);
  color: var(--white);
  outline: none;
  font-family: "Roboto";
  font-weight: 400;
  border: none;
  border-radius: 2px;
}

.PhoneloginButton:hover {
  background-color: var(--primary-colorHover);
}

.userResgistrationContinuebtn {
  background-color: var(--primary-color);
  color: var(--white);
  outline: none;
  font-family: "Roboto";
  font-weight: 400;
  border: none;
  width: 100%;
  padding: 0.4rem;
  border-radius: 2px;
}

.footer_phoneLoginModal button {
  text-decoration: none;
  color: var(--text-grey);
  font-family: "Roboto";
  font-weight: 400;
}

.useRegistration_body {
  height: 500px;
  display: flex;
  padding: 2.5rem 2.5rem;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  gap: 0.4rem;
}

.useRegistration_body p {
  color: var(--text-grey);
}

.footer_phoneLoginModal button:hover {
  text-decoration: underline;
  color: var(--text-grey);
}

.footer_phoneLoginModal {
  text-align: center;
}

.react-international-phone-country-selector-button__button-content {
  padding: 0.2rem 0.5rem;
}

.dfoodoterms_agreement {
  display: flex;
  margin-top: 1rem;
  align-items: center;
  /* flex-direction: row; */
  gap: 3px;
}

.dfoodoterms_agreement p {
  font-family: "Roboto";
  font-size: 15px;
  /* color: var(--primary-color); */
  font-weight: 400;
  margin-bottom: auto;
}

.dfoodoterms_agreement input {
  margin: 0 !important;
  height: 9px;
  width: 9px;
}

.userRegistration_phoneNumber input {
  outline: none;
  border: none;
  width: 100%;
}

#phoneNumberUserRegistration {
  padding: 0rem;
}

.react-international-phone-input-container {
  padding: 0rem;
}

.bg-body-tertiary {
  background: rgba(255, 255, 255, 1);
  box-shadow: -1.5px 4.51px 7.52px 0px rgba(0, 0, 0, 0.08);
}

.mobile_phone_container {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  box-shadow: 0px 0px 0px 0.75px rgba(213, 216, 220, 1) inset;
  margin-right: 0.5rem;
  border-radius: 4px;
  align-items: center;
  font-family: "Roboto";
  font-size: 14px;
}

.mobile_phone_container img {
  width: 10px;
  margin: 0;
  padding: 0;
}

.loginButton,
.loginButtonMobile {
  width: fit-content;
  font-family: "Roboto";
  font-size: 14px;
  background-color: var(--primary-color);
  color: var(--white);
  border: none;
  font-weight: 400;
  border-radius: 4px;
  padding: 0.4rem 1rem;
  height: fit-content;
  max-height: fit-content;
}

.loginButton:hover,
.loginButtonMobile:hover {
  background-color: var(--primary-colorHover);
}

.upper_header_container {
  display: flex;
  font-family: "Roboto";
  font-size: 14px;
  align-items: center;
  font-weight: 400;
  color: var(--secondary-black);
  flex-direction: row;
  gap: 0.8rem;
  padding: 0.1rem 0rem;
  justify-content: right;
}

.upper_header_container p {
  margin: 0;
  font-size: 14px;
  align-items: center;
  font-family: "Roboto";
  font-weight: 400;
  color: var(--secondary-black);
}
.upper_header_wrapper {
  background-color: rgba(249, 250, 250, 1);
}
.upper_header_wrapper a {
  text-decoration: none;
  color: var(--text-grey);
  border-bottom: 1px solid transparent;
}

.upper_header_container_mobile a {
  text-decoration: none !important;
  color: var(--text-grey);
}

.upper_header_wrapper a:hover {
  border-bottom: 1px solid var(--primary-color);
}

.locationDropDown {
  display: flex;
  align-items: center;

  gap: 0.3rem;
  label {
    font-size: 14px;
    color: var(--text-grey);
    font-weight: 500;
    cursor: pointer;
    margin-top: 1px;
  }
  img {
    cursor: pointer;
    width: 15px;
  }
}
.phoneApp_modal_register_my_venue {
  display: flex;
  flex-direction: column;
  font-family: "Roboto";
  text-align: center;
  padding: 2rem 1rem;
}

.phoneApp_modal_register_my_venue p {
  font-family: "Roboto";
  color: var(--text-grey);
}

.phoneApp_modal_register_my_venue img {
  width: 150px;
  aspect-ratio: 1;
  margin-left: auto;
  margin-right: auto;
}

.mobile_wrapper_header {
  align-items: center;

  p {
    margin: 0;
  }
}

#image_from_letter {
  padding: 0 !important;
}

.mobile_wrapper_header a {
  padding: 0.5rem 0.5rem;
  align-items: center;
  display: flex;
  gap: 0.2rem;
}

.stickyHeader,
.stickyHeaderOther {
  background-color: rgba(249, 250, 250, 1);
  position: sticky !important;
  top: 0;
  z-index: 999;
}
.stickyHeaderOther {
  width: 100%;
  backdrop-filter: blur(2px);
  background-color: rgba(249, 250, 250, 0.352) !important;
  position: fixed !important;
  height: 100px !important;
}
.stickyHeaderOther.scrolled {
  background-color: white !important;
  box-shadow: -1.5px 4.51px 7.52px 0px rgba(0, 0, 0, 0.08);
  height: 95px;

  .dropdown1 {
    color: var(--text-grey);
  }
  .mobile_wrapper_header {
    background-color: white !important;
  }
}

.getHelpClssPtnerPAge {
  cursor: pointer;
  text-decoration: none;
  color: var(--text-grey);
  border-bottom: 1px solid transparent;
}
.stickyHeaderOther .upper_header_container {
  background-color: transparent !important;
}
.navbar {
  padding: 1rem 0rem;
}

.seachVenue_section_searchbar_headerlocation {
  border: 1px solid #f0f0f0;
  width: 100%;
  padding: 0.3rem 1rem !important;
  font-family: "Roboto";
  margin-bottom: 1rem;
  border-radius: 5px;
}

.location_wrapper_headerModal {
  display: flex;
  font-family: "Roboto";
  background-color: var(--white);
  color: var(--primary-color);
  display: flex;
  gap: 0rem;
  cursor: pointer;

  margin-bottom: 0.5rem;
  align-items: center;

  img {
    padding: 0.2rem 0.5rem;
    border-radius: 50%;
    aspect-ratio: 1;
  }

  h6 {
    font-weight: 400;
    margin-bottom: 0;
  }
}

.popular_cities_header {
  text-align: center;
  font-family: "Roboto";
  color: var(--secondary-black);
}

.otp_update,
.otp_section,
.last_section {
  display: none;
}
.otp_section {
  input {
    border-radius: 4px !important;
  }
}

.width100per {
  width: 100%;
}

.wifth_chckbox {
  width: 20px !important;
}
.ShowLocationImg {
  border-radius: 50%;
  background-color: transparent;
}
.mapped_cities_wrapper {
  font-family: "Roboto";
  margin-top: 2rem;
}

.cities_mapped {
  display: flex;
  flex-wrap: wrap;
  gap: 0.3rem;
  list-style-type: none;
  font-size: 14px;
  font-family: "Roboto";

  li:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  li {
    color: var(--text-grey);
  }
}

.location_modal_text {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  text-align: center;
}

.cities_mapped_locationModal {
  display: flex;
  margin-left: 10px;
  flex-wrap: wrap;
  gap: 1.6rem;
  justify-content: left;
  font-family: "Roboto";
  font-size: 14px;
  text-align: center;

  a {
    text-decoration: none !important;
    color: var(--text-grey);
  }

  img {
    width: 70px;
  }
}

.cities_mapped_locationModal a:hover {
  text-decoration: underline;
}

.mobileLocationSHow {
  display: none;
  li {
    list-style: none;
  }
}
.Notlogin {
  padding-left: 0.5rem;
  display: flex;
}

.onBoadHeadLogInout {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .logOutbttnOboard {
    background-color: transparent;
    border: none;
    background-color: var(--primary-color);

    color: var(--white);
    border-radius: 5px;
    font-size: 14px;
    padding: 0.4rem 0.6rem;
    margin-bottom: 4px;
    &:hover {
      background-color: var(--primary-colorHover);
    }
  }
}

.LocationModdlll {
  margin-top: 5rem;
  font-family: "Roboto";
  .modal-content {
    border-radius: 8px;
  }
  .modal-header {
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: row !important;
    position: relative;
  }
  .modal-body {
    padding: 0rem;
  }

  .SelctdCity {
    display: flex;
    justify-content: center;
    p {
      padding-top: 0.7rem;
      font-size: 15px;
      font-weight: 400;
      margin: 0;
      text-align: center;
      padding-bottom: 0.7rem;
    }
  }
}

.horiZonllLine {
  height: 1px;
  width: 100%;
  background: var(--text-grey);
  margin: 1px;
}
.location_city_imgs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.4rem;
  padding-bottom: 0.7rem;
}

.location_city_imgs .citySelecttt {
  transition: color 0.3s ease;
}

.location_city_imgs > div:hover .citySelecttt {
  color: var(--primary-color);
}

.location_city_imgs img {
  transition: border 0.3s ease;
}

.locationImg {
  padding-top: 0.7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  background-color: transparent;
  text-decoration: none;
  gap: 5px;
  img {
    width: 66px;
    border-radius: 50%;
  }

  .citySelecttt {
    font-size: 15px !important;
    color: var(--text-grey);
  }
}
.closeModllIcnn {
  position: absolute;
  cursor: pointer;
  top: -1.6rem;
  right: -1.8rem;
  background-color: var(--white);
  padding: 1px 5px;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  height: fit-content;
  width: fit-content;
  transform: scale(1);
  transition: transform 0.2s ease;
  &:hover {
    transform: scale(1.1);
    .cloSeiccnn {
      color: var(--primary-color);
    }
  }
}
.cloSeiccnn {
  padding-left: 1px;
  padding-bottom: 1px;
  transition: color 0.3s ease;
}
@media screen and (max-width: 991px) {
  .mobileLocationSHow {
    display: flex;
  }
  .WebSHowonly {
    display: none;
  }
  .ToggleMobileLocationBar {
    display: none;
  }
  .mobile_wrapper_header {
    backdrop-filter: blur(2px);
    background-color: rgba(249, 250, 250, 0.352) !important;
    padding-left: 0px;
  }

  .navbar-collapse {
    margin-top: 0.2rem;
    margin-left: -1rem;
    padding-left: 0.5rem;
  }
  #navbarScroll {
    padding-left: 0px;
  }
  .mobile_phone_container {
    margin-left: 0.5rem;
    padding-left: 4px;
  }
}
@media screen and (min-width: 992px) {
  .loginButton {
    display: inline-block;
  }
  .loginButtonMobile {
    display: none !important;
  }
  .Notlogin {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .loginButton {
    display: none !important;
  }
  .loginButtonMobile {
    margin-left: 7px;
    display: inline-block;
  }
}
@media screen and (max-width: 1000px) {
  .location .modal-content {
    margin-left: 0;
    width: auto;
  }
}
.seachVenue_section_searchbar {
  width: 96% !important;
  margin: auto;
}
.city_container img {
  width: 66px;
}
.citySelecttt.active {
  color: var(--primary-color);
}

.verificationNum {
  color: var(--primary-color);
}
.ERRORMSGG {
  display: flex;
  margin-top: 6px;
  margin-left: 10px;
  font-size: 12px;
  text-align: start;
  color: var(--primary-color) !important;
}

/* .cities_mapped_locationModal img{
    width: 65px;
} */
@media (min-width: 768px) and (max-width: 1024px) {
  .city_container img {
    width: 62px;
  }
}

@media only screen and (min-width: 1101px) {
  .city_container img {
    width: 60px;
  }
  .cities_mapped_locationModal {
    padding: 20px 20px;
    gap: 1.6rem;
    margin-left: 0px;
  }
  .city_container p {
    font-weight: 400;
  }
}
@media screen and (max-width: 495px) {
  .dfoodoterms_agreement {
    align-items: flex-start;
    input {
      margin-top: 7px !important;
    }
  }
  .dfoodoterms_agreement p {
    font-size: 14px;
  }
}
@media screen and (max-width: 576px) {
  .closeModllIcnn {
    top: 3px;
    right: 3px;
    background-color: transparent;
    box-shadow: none;
  }
}
