.progress-container {
  width: 100%;
  margin: 20px auto;
  position: relative;
}

.progress-bar {
  height: 4px;
  background-color: #e0e0df;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
}

.progress-fill {
  height: 100%;
  background-color: var(--primary-color);
  width: 0%;
  transition: width 0.3s ease-in-out;
}

.progress-text {
  position: absolute;
  top: -26px;
  right: 4px;
  font-weight: bold;
  color: var(--primary-color);
  span {
    font-size: 14px;
    font-weight: 400;
  }
}

.progress-fill {
  height: 100%;
  background-color: var(--primary-color);
  width: 0%;
  transition: width 0.3s ease-in-out;
}

/*step-0 */

.leftSideCont {
  display: flex;

  justify-content: center;

  width: 100%;
  /* img {
    width: 100%;
  } */
}

.leftSideCont img {
  width: 100%;
  height: 100%;
}
.OperationhoursideImg {
  height: 340px !important;
  width: 340px !important;
  margin-top: 2rem;
}
.input-error {
  border: 1px solid var(--primary-color);
}
.restName {
  margin-bottom: 0.8rem;
}
.HeadBussiness,
.HeadBussiness2 {
  padding: 0 5px;
  left: 8px;
  top: -8px;
  z-index: 99;
  transition: all 0.2s ease;
  position: absolute;
  font-size: 13px;
  color: #737373;
  background-color: white;
}
.HeadBussiness2 {
  padding: 0 5px;
  left: 19px;
}

.containerRight {
  display: flex;
  flex-direction: column;
  h3 {
    margin: 0;
    font-size: 25px;
    font-weight: 400;
    line-height: 36px;
  }
  P {
    padding-left: 3px;
    /* padding-bottom: 1rem; */
    /* margin: 0; */
    color: #737373;
    font-size: 13px;
  }
  span {
    margin-left: 2px;
  }
}
.containerRight input {
  position: relative;
  font-size: 14px;
  padding: 0.8rem;
  /* cursor: pointer; */
}

.containerRight input::placeholder {
  transition: color 0.3s ease;
}

.containerRight input:hover::placeholder {
  color: black;
}

.containerRight input.active {
  border: 1px solid var(--primary-color) !important;
}
.form-control {
  background-color: transparent;
  box-shadow: none !important;
  outline: none !important;
}
.form-control:hover {
  border-color: black;
}
.form-control:-webkit-autofill,
.form-control:-webkit-autofill:hover,
.form-control:-webkit-autofill:focus,
.form-control:-webkit-autofill:active {
  background-color: transparent !important;
  box-shadow: 0 0 0px 1000px transparent inset !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  color: inherit !important;
  transition: background-color 5000s ease-in-out 0s;
}

.form-control:autofill {
  background-color: transparent !important;
  box-shadow: 0 0 0px 1000px transparent inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

.NextButton button,
.newClass2 button,
.newClass button,
.skipbutton button {
  color: #000000;
  padding: 0.5rem 2rem;
  background-color: #a8a8a8;
  border: transparent;
  font-size: 14px;
  border-radius: 2px;
  transition: all 0.1s ease-in;
  cursor: default;
}

/* .newClass button:hover,
.NextButton button:hover {
  color: var(--white);
  background-color: var(--primary-colorHover);
  border: transparent;
} */

.emptyFiled button,
.operationHoursBtn button {
  color: #000000;
  padding: 0.5rem 2rem;
  background-color: #a8a8a8;
  border: transparent;
  font-size: 14px;
  border-radius: 2px;
  transition: all 0.1s ease-in;
  cursor: not-allowed;
  opacity: 0.5;
}
.operationHoursBtn button {
  cursor: pointer !important;
  background-color: #a8a8a8 !important;
  color: var(--white);
  opacity: 1 !important;
}
.operationHoursBtn button:hover {
  background-color: var(--primary-colorHover) !important;
}
.nonEmptyBttn button {
  color: var(--white);

  padding: 0.5rem 2rem;
  background-color: var(--primary-color);
  border: transparent;
  font-size: 14px;
  border-radius: 2px;
  transition: all 0.1s ease-in;
  cursor: pointer;
  opacity: 1;
  &:hover {
    color: var(--white);
    background-color: var(--primary-colorHover);
    border: transparent;
  }
}
.rightSideCont {
  display: flex;
  margin-top: 1rem;
  width: 100%;
}
.maxwidthClass {
  margin-top: 0.9rem;
  max-width: 100%;
}

.CountryNameE .css-t3ipsp-control {
  outline: none;
  border: none;
  box-shadow: none;
  background-color: transparent;
}
/* .CountryNameE .css-16xfy0z-control {
  outline: none;
  border: none;
  box-shadow: none;
  background-color: wh;
} */
.CountryNameE .css-hlgwow {
  padding: 0px;
}
.resTdiscription {
  padding-top: 1rem;
  height: 130px;
  font-size: 14px;
  resize: none;
}
.mobileCOntnt {
  display: flex;
  border: 1px solid #a1a1a1;
  border-radius: 5px;
  align-items: center;
  input {
    border: none;
  }
}

.emailContr {
  input {
    border: none !important;
  }
}
.mobileCOntnt.input-focus {
  border-color: var(--primary-color);
}
.mobileCOntnt button {
  font-size: 13px;
  white-space: nowrap;
  background-color: transparent;
  border: none;
  color: var(--primary-color);
}
.verifYbttn {
  color: #dddcda !important;
}

.border-green {
  border: 1px solid var(--green) !important;
}
@media screen and (max-width: 500px) {
  .maxwidthClass {
    max-width: 100%;
  }
  .containerRight h3 {
    font-size: 18px;
    line-height: 26px;
  }
  .containerRight p {
    padding-top: 10px;
  }

  .BackHeading {
    margin-left: 0 !important;
  }

  .padding000 {
    padding: 0;
  }
}

.conditonMsgSHow {
  margin-left: 4px;
  font-size: 13px;
  color: var(--primary-color);
  margin-top: 0.5rem;
}
.shake {
  animation: shake 0.5s;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}
.VerificationBttn {
  margin-right: 0.2rem;
  padding: 0.3rem 1.5rem;
  min-height: 35px;
  min-width: 92px;
}
.Verificationcontainer {
  border-radius: 8px;
  padding: 0.5rem;
  border: 1px solid #e0e4e7;
  align-items: center;
  transition: border-color 0.4s ease-in-out;
  input {
    margin: 0;
  }
}
.Verificationcontainer:hover {
  border-color: black;
}
.Verificationcontainer .form-control {
  border: none !important;
}
.Verificationcontainer.active {
  border: 1px solid #86b7fe;
}

.Verificationcontainer .form-control {
  background-color: transparent;
  padding: 0;
}
.form-control {
  border: 1px solid #9a9a9a;
}
.inputCHeck input[type="checkbox"] {
  display: none;
}

.containerRight .inputCHeck input[type="checkbox"] + .custom-checkbox {
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  display: inline-block;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
}

.containerRight .inputCHeck input[type="checkbox"]:checked + .custom-checkbox {
  background-color: var(--primary-color);
  border: 2px solid var(--primary-color);
}

.containerRight .inputCHeck .custom-checkbox:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
}

.containerRight
  .inputCHeck
  input[type="checkbox"]:checked
  + .custom-checkbox:after {
  opacity: 1;
}

.form-control:focus {
  border: 1px solid var(--primary-color);
}
.ActiveFormCOntrol:focus {
  border: none !important;
}
.backSTepOPrationImg {
  margin: 0px !important;
}

/*-----step2---*/
.BackHeading {
  align-items: flex-start;
  margin-left: -1.5rem;
  margin-bottom: 0.5rem;

  button {
    border: transparent;
    background-color: transparent;
  }
  img {
    margin-left: -10px;
  }
}
.imgAnimeLeft {
  margin-top: 2rem;
}
.BackHeading {
  margin-top: 1rem;
}
.containerRight select {
  font-size: 14px;
  font-weight: 400;
  color: #737373;
  padding: 0.8rem;
}
.css-13cymwt-control {
  border: transparent !important;
  outline: none !important;
  box-shadow: transparent !important;
  border-style: none !important;
}
.contnrsteps {
  height: 70vh;
  overflow: hidden;
}
/*--Step3*/
.box1 {
  padding: 0.8rem;
  border-radius: 3px;
  border: 1px solid black;
}

.AllContent {
  display: flex;
  flex-direction: column;
  p {
    font-weight: 500;
    padding: 0;
    font-size: 14px;
    margin: 0;
  }
  label {
    font-size: 11px;
    color: #737373;
  }
}
.dinImg {
  display: flex;
  height: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;
}
.inputCHeck,
.inputCHeck2 {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  input {
    padding: 6px;
    height: 10px;
    width: 10px;
    box-shadow: none;
    outline: none;
  }
}
.inputCHeck2 input {
  height: 14px;
  width: 14px;
}
/*---step-4--*/
.vegNon {
  height: 30px;
  width: 30px;
}
.inputCHeck2 input[type="radio"] {
  display: none;
}

.inputCHeck2 input[type="radio"] + .custom-radio {
  width: 15px;
  height: 15px;
  border: 2px solid #ccc;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.inputCHeck2 input[type="radio"]:checked + .custom-radio {
  background-color: var(--primary-color);
  border: 2px solid var(--primary-color);
}

.inputCHeck2 .custom-radio:after {
  content: "";
  position: absolute;
  left: 2px;
  top: 2px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
  opacity: 0;
}

.inputCHeck2 input[type="radio"]:checked + .custom-radio:after {
  opacity: 1;
}

/*-------step-6--*/
.footerbtttn {
  margin-top: 0.8rem;
  gap: 10px;
  display: flex;
  align-items: center;
}

.skipbutton button {
  cursor: pointer !important;
  margin-top: 0;
  border: transparent;
  background-color: #e7e6e4;
}
.skipbutton button:hover {
  color: var(--white);
  background-color: var(--primary-color);
}

/*------------------------------step7------------------------------------------*/

.step7height {
  height: calc(70vh - 100px) !important;
}
.step7Img {
  margin-top: 3rem;
  height: 280px !important;
  width: 295px !important;
}
.marginLEft {
  margin-left: 6px;
}

.leftSideContant,
.rightSideContant {
  display: flex;
  gap: 1.3rem;
  flex-direction: column;
  padding: 6px;
}
.RestInputs {
  width: fit-content;
}

.retaruFeature {
  img {
    margin-right: 10px;
    margin-left: 10px;
  }
  label {
    font-size: 14px;
    font-weight: 400;
  }
}

.retaruFeature input {
  height: 12px;
  width: 12px;
  margin: 0;
}
.BottmRestaurentChek {
  margin-left: 0.4rem;
  margin-top: 1rem;
  label {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 400;
  }
  input {
    height: 12px;
    width: 12px;
    margin: 0;
  }
}

.TooltipBox {
  border-radius: 5px;
  border: 1px solid #9a9a9a;
  padding: 8px 10px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  p {
    padding: 0;
  }
}
.tooltipImg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
}
.RestCont {
  input {
    cursor: pointer;
  }
}
.CuisenDIv {
  label {
    font-size: 14px;
  }
  input {
    height: 12px;
    width: 12px;
  }
}
.MarginBttm {
  margin-bottom: 4px;
}
/*---Step 9*/
.overFLowClass {
  white-space: nowrap;
  flex-wrap: nowrap;
  /* width: 70vw; */
  /* overflow: auto; */
}
.PaddingNoneClass select {
  padding: 0px;
}
.FlexColunm {
  flex-direction: column;
}
.applyTheSameSec {
  padding-left: 3rem;
  padding-bottom: 1rem;
}
.paddingTOp {
  padding-top: 1rem;
  margin-bottom: 0.4rem;
}
.colorText {
  color: var(--primary-color) !important;
}
.divRight {
  width: 100%;
  justify-content: end;
  padding-right: 4rem;
  img {
    height: 37.2px !important;
    width: 37.2px !important;
  }
}
.marginn {
  margin-right: 6px;
}
.marginnLeft {
  margin-left: 6px;
}
.overFdiv {
  min-height: 70px;
  max-height: 100%;
  /* overflow-x: auto; */
}
.ContentHours {
  /* height: 80vh;
  overflow: auto; */
  overflow: hidden;
  padding: 1rem;
  background-color: #f8f8f8;
}
@media screen and (min-width: 768px) {
  .timeRow0 {
    min-width: 627px;
    width: 100%;
    overflow: auto;
  }
}

.overFLowClass {
  min-width: 627px;
}
@media screen and (max-width: 767px) {
  .addTimingRowContainer {
    width: fit-content;
  }
  .MobileViewSHiw {
    display: flex;
    justify-content: center;
  }
}
/* @media screen and (max-width: 768px) { */
.overflowHeight {
  overflow-y: scroll;
  height: calc(100vh - 170px);
  padding-bottom: 4rem;
  padding-top: 0.5rem;
}

.overflowHeightStep8 {
  height: calc(100vh - 270px);
  overflow-y: scroll;

  padding-bottom: 4rem;
  padding-top: 0.5rem;
}

.overflowHeightstep1 {
  overflow-y: scroll;
  height: calc(100vh - 93px);
  padding-bottom: 4rem;
  padding-top: 0.5rem;
}

.overflowHeight3 {
  overflow-y: scroll;
  height: calc(100vh - 247px);
  padding-bottom: 4rem;
  padding-top: 0.5rem;
}

.overflowHeightstep1::-webkit-scrollbar {
  display: none;
}

.overflowHeightOperationHour {
  overflow-y: scroll;
  height: calc(100vh - 247px);
  padding-bottom: 4rem;
  padding-top: 0.5rem;
}

.overflowHeight12 {
  overflow-y: scroll;
  height: calc(100vh - 150px);
  padding: 0.5rem;
  padding-bottom: 1rem;
}

@media screen and (max-width: 991px) {
  .overflowHeight {
    height: calc(100vh - 235px);
  }
}

.overflowHeight::-webkit-scrollbar {
  width: 4px;
  display: none;
}
.overflowHeight::-webkit-scrollbar-thumb {
  background-color: #eeeeee !important;
}
.contFOrm {
  padding: 20px 0;
}
/*--step-10--*/
.timeSHort {
  padding-left: 1px;
  color: #737373;
  font-size: 14px;
}

.timeSLots {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  input {
    outline: none;
    cursor: pointer;
    height: 14px;
    width: 14px;
  }
}

.ContainerCheckbox {
  display: flex;
  gap: 10px;
  align-items: center;
}

.dragePhotoCont,
.dragePhotoCont2 {
  height: 100%;
  cursor: pointer;
  margin-left: 3px;
  display: flex;
  /* padding: 1.2rem; */
  max-width: 40%;
  gap: 5px;
  border-radius: 5px;
  max-height: 150px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1rem;
  border: 1px solid #747474;
  p {
    padding: 0;
  }
}
.photoImg {
  align-items: center;

  p {
    color: #007aff;
  }
  label {
    font-size: 14px;
    color: #007aff;
  }
  button {
    font-size: 14px;
    color: #007aff;
    border: transparent;
    background-color: transparent;
  }
}
.mrginCLass p {
  margin: 0;
}
.BannerCLass {
  height: 85px !important;
  width: 85px !important;
}
.BanerDIV {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.RetImg {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
  }
  label {
    color: #737373;
    font-size: 14px;
  }
}

.dragePhotoCont2 {
  height: 124.52px;
  width: 100%;
  margin: 0;
  max-width: 100%;
}

.containerImgs,
.containerMenuImgs {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  img {
    width: 237.11px;
    height: 124.52px;
  }
}
.MaxSizeLAbel {
  margin-left: 3px;
  margin-top: 0.5rem;
  font-size: 13px;
  color: var(--primary-color);
}

.containerMenuImgs {
  img {
    width: 136px;
    height: 155px;
  }
}
.imgCont,
.menuImgs {
  position: relative;
}
.imgCont {
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  border-radius: 8px;
  overflow: hidden;
}
.thumb-img {
  padding: 2px;
  width: 100% !important;
  height: 100% !important;
  object-fit: contain !important;
}
.deleteImgicn {
  position: absolute;
  bottom: 4px;
  right: 7px;
  background-color: rgba(0, 0, 0, 0.566);
  border-radius: 50px;
  padding: 3px;

  font-size: 20px;
  color: #007aff;
  cursor: pointer;
  height: 28px !important;
  width: 28px !important;
}
.deleteImgicn2 {
  position: absolute;
  bottom: -35px;
  right: 9px;
  background-color: rgba(0, 0, 0, 0.566);
  border-radius: 50px;
  padding: 3px;

  font-size: 20px;
  color: #007aff;
  cursor: pointer;
  height: 28px !important;
  width: 28px !important;
}
.deleteImgicn:hover {
  background-color: rgb(0, 0, 0);
}

#test_form_for_image {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}
@media screen and (max-width: 767px) {
  .leftSideCont {
    display: none;
  }
}

/*----step13---*/
.textDivArera {
  position: relative;
  border-radius: 3px;
  padding: 0.4rem;
  border: 1px solid #747474;
  textarea {
    margin-bottom: 0.4rem;
    border: none;
    position: relative;

    width: 100%;
    height: 150px;
    resize: none;
    border-radius: 3px;
    outline: none;
  }
}

.charCount {
  right: 4px;
  bottom: 4px;
  position: absolute;
  color: #737373;
  font-size: 13px;
}

/*step14*/
.EditForm {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.headingResto {
  padding-top: 0.5rem;
  color: #737373;
  font-size: 18px;
  font-weight: 500;
  padding: 15px;
  padding-bottom: 0;
}

.contFOrm .react-tabs__tab--selected {
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
  border-bottom: 2px solid var(--primary-color);
}
.contFOrm {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  overflow: hidden;
  border-radius: 5px;
}

.section-buttons {
  overflow-x: auto;
  display: flex;
  gap: 1.3rem;
  margin-top: 1rem;
  margin-bottom: 16px;
  padding: 15px;
  padding-bottom: 0;
}

.section-button {
  white-space: nowrap;
  font-size: 14px;
  background: none;
  border: none;
  cursor: pointer;
  color: #737373;
  transition: color 0.3s;
  font-weight: 400;
  padding: 2px 0;
}

.section-button.active {
  border-bottom: 2px solid var(--primary-color);
  font-weight: bold;
}

.content-container {
  max-height: 400px;
  overflow-y: scroll;
  /* padding-right: 10px; */
}

.content-container::-webkit-scrollbar {
  display: none !important;
}

.content-box {
  padding: 5px 0px;
  /* margin-bottom: 20px; */
  /* border-bottom: 1px solid #e7e6e4; */
}
.content-box.visible {
  display: block;
}

.underContent {
  border-top: 1px solid #e7e6e4;
  border-bottom: 1px solid #e7e6e4;
  padding: 0.5rem 1rem;
  transition: all 0.1s ease-in;
  p {
    font-size: 14px;
    color: #484848;
    font-weight: 400;
    margin: 0;
  }
  label {
    /* margin-top: 0.3rem; */
    font-size: 14px;
    color: #484848;
    /* font-weight: 500; */
  }
  input {
    padding: 6px;

    border-radius: 0;
    border: transparent;
    margin-top: 0.5rem;
    font-size: 13px;
    color: #737373;
    font-weight: 500;
  }
  img {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin-left: 3px;
  }
}
.AboutCont,
.contactUs {
  gap: 1rem;
  display: flex;
  flex-direction: column;
  h4 {
    margin-bottom: 0.5rem;
    font-size: 17px;
  }
}

.underContent .form-control:focus {
  border-bottom: 1px solid var(--primary-color);
}
.Imggs {
  display: flex;
  flex-direction: column;
}
.uploadBox {
  max-width: 150px;
  margin-top: 1.2rem;
  padding: 0.7rem;
  border: 1px solid #808080;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;
  label {
    font-size: 13px;
    color: #007aff;
    font-weight: 400;
  }
  p {
    font-size: 13px;
  }
  span {
    font-size: 13px;
  }
}
.mapdImgrest,
.mapdImgrest2 {
  margin-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  position: relative;
  img {
    height: 90px;
    width: 150px;
  }
}
.imgBx {
  position: relative;
}
.mapdImgrest2 {
  img {
    height: 90px;
    width: 90px;
  }
}
.DeletImgg {
  background-color: rgba(0, 0, 0, 0.596);
  border-radius: 50px;
  position: absolute;
  padding: 3px;
  right: 4px;
  bottom: 4px;
  height: 20px !important;
  width: 20px !important;
}
.DeletImgg:hover {
  background-color: rgb(0, 0, 0);
}
.SvBtn,
.editingClass {
  margin-left: 2px;
  color: #737373;

  padding: 0.3rem 1.5rem;
  background-color: #e7e6e4;
  border: transparent;
  font-size: 14px;
  border-radius: 2px;
}
.editingClass:hover {
  color: var(--white);
  background-color: var(--primary-color);
}
.SubmtBtN {
  border-radius: 2px;
  font-size: 14px;
  background-color: var(--primary-color);
  border: none;
  padding: 0.3rem 1.5rem;
  color: var(--white);
}
.SubmtBtN:hover {
  background-color: var(--primary-colorHover);
}

.thnkMsg {
  color: #535969;
  font-size: 22px;
  margin-bottom: 1rem;
  font-weight: 600;
}
.submtformmdl .modal-body {
  padding: 3rem 0.5rem;
}
.Scndhead,
.thirdhead {
  font-weight: 600;
  color: #535969;
  font-size: 17px;
  margin-bottom: 0.8rem;
}
.thirdhead {
  font-weight: 400;
  font-size: 15px;
}

.succSSimg,
.succSSimg2 {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 200px;
  img {
    height: 100px;
    width: 100px;
  }
}
.succSSimg {
  img {
    height: 120px !important;
    width: 120px !important;
  }
}
.image_preview {
  gap: 10px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
  /* justify-content: space-between; */
  align-items: center;
}
.image_preview2 {
  width: 194px;
  aspect-ratio: 1;
  height: 125px;
}

.image-preview-parent {
  display: flex;
  flex-direction: row;
  align-content: stretch;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 30px;
  gap: 3%;
}
.image-preview {
  margin: 5px;
}
.remove-icon {
  background-color: rgba(5, 3, 3, 0.8);
  position: absolute;
  z-index: 30;
  border-radius: 10px;
  padding: 4px;
  top: 5px;
  right: 5px;
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;
  border: none !important;
}
.thumb {
  /* width: 100%; */
  position: relative;
  width: calc(100% / 3 - 30px);
  aspect-ratio: 1;
  height: 117px;
  margin: 5px;
}
.thumb2 {
  position: relative;

  aspect-ratio: 1;

  margin: 5px;
}
.pdingClss {
  padding-left: 0;
}

.AllreadiSubmit {
  height: calc(100vh - 82px);
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 576px) {
  .thumb {
    width: calc(100% / 2 - 15px);
  }

  .thumb3 {
    width: 150px !important;
  }
  .pdingClss {
    margin-top: 1.5rem;
    padding-left: 13px;
    .HeadBussiness {
      top: -0.7rem;
      left: 1.2rem;
    }
  }
}
@media screen and (max-width: 776px) {
  .thumb2 {
    width: 142px;

    height: 117px;
  }
}

.thumb-img {
  display: block;
  /* width: 100%;
  height: 100%; */
  border-radius: 5px;
}

.overflowCOntainerOn {
  min-height: 100%;
  height: 100%;
  overflow-y: auto;
}

.thumb3 {
  width: 180px;
}

.form-control:disabled {
  background-color: transparent !important;
}
.seattooltip {
  border: 1.5px solid #737373;
  display: flex;
  position: relative;
  border-radius: 6px;
}

.seattooltip img {
  cursor: pointer;
  margin-right: 1rem;
}

.seattooltip input {
  border: none;
}
.seattooltip.input-focused {
  border-color: var(--primary-color);
}

.seattooltip .tooltiptext {
  width: 90%;
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 7px;
  right: 40px;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 13px;
}

.seattooltip img:hover + .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.divRight {
  position: relative;
}

.tooltip-image {
  cursor: pointer;
}

.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: rgb(104, 104, 104);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 0px;
  right: 5rem;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.divRight:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.css-1nmdiq5-menu {
  z-index: 100 !important;
}

.css-16xfy0z-control {
  background-color: #ffffff !important;
  border: none !important;
}

.zipcodeDiv {
  padding-left: 5px !important;
}

.zipcodeDiv input {
  height: 52px;
}

.stateDiv {
  padding-right: 5px !important;
}

.step1Contrr {
  padding-top: 0.4rem;
  overflow: auto;
  height: calc(100vh + 30px);
}
.firsPhone::placeholder {
  opacity: 0.4;
  color: #9a9a9a !important;
  &:hover {
    color: #9a9a9a !important;
  }
}
@media screen and (max-width: 400px) {
  .after400Col {
    flex-direction: column;
  }

  .stateDiv,
  .zipcodeDiv {
    width: 100%;
  }

  .zipcodeDiv {
    padding-left: 12px !important;
  }

  .stateDiv {
    padding-right: 12px !important;
    margin-bottom: 0.5rem;
  }

  .zipcodeDiv input {
    height: 52px;
  }
}

.successMessage {
  color: #33cc33;
  font-size: 14px;
}

.timeli {
  margin-bottom: 0.5rem;
}

/* div:where(.swal2-icon) {
  width: 3rem !important;
  height: 3rem !important;
} */

.backCOlor {
  background-color: #f3f3f3;
}

.underContent:hover {
  background-color: #f3f3f3;
}

/* .underContent:hover img {
  margin-left: 2rem;
} */
