@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.timeli_hide {
  display: none !important;
}

.normal_hide {
  display: none !important;
}

.calendar-root {
  --grid-gap: 2px;
  --grid-height: 700px;
  --text-color-light: rgba(0, 0, 0, 0.4);
  --text-color-primary: rgba(0, 0, 0, 0.7);
  --grid-background-color: rgb(211, 205, 198);
  --grid-foreground-color: white;
  --space-sm: 4px;
  --space-md: 8px;
  --space-lg: 16px;
  width: 100%;
}

/* | Sun | Mon | Tue | Wed | Thu | Fri | Sat  */
.day-of-week-header-cell {
  color: var(--white-text);
  background-color: var(--grey-bg);
  padding: 0.8rem 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  box-shadow: 0px 2px 4px 0px #00000040;
}

.days-of-week,
.days-grid {
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  /* box-shadow: 0px 0px 3.9932374954223633px 0px #00000040; */
}

.days-grid {
  height: var(--grid-height);
  position: relative;
  background-color: var(--primary-bg-color);
}

.day-grid-item-container {
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 3.9932374954223633px 0px #00000040;
  transition: all 0.3s ease-in-out;
  margin: 2px;
  cursor: pointer;
}

.day-grid-item-container:hover {
  background-color: var(--greenpistachio-bg);
}

.day-grid-item-container:not(.current-month) .day-grid-item-header {
  color: var(--text-color-light);
}

/* Position the day label within the day cell */
.day-grid-item-container .day-grid-item-header {
  color: var(--light-text);
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  font-weight: bold;
  background-color: var(--primary-bg);
  border-radius: 50%;
  box-shadow: 0px 0px 2.4957733154296875px 0px #00000040;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -6px;
  left: 15px;
  display: block;
}

.monthnumber {
  text-align-last: center;
  margin-bottom: 0px;
}

.monthname {
  text-align-last: center;
  margin-bottom: 0px;
  font-size: 11px;
  margin-top: -4px;
}

.day-grid-item-container > .day-content-wrapper {
  flex: 1;
  min-height: 0;
  position: relative;
}

.navigation-header {
  display: flex;
}

.navigation-header > * {
  margin-right: var(--space-md);
}

.day_grid_item_container {
  background-color: var(--lightGrey-bg);
  position: relative;
  margin-top: 1rem;
}

.calenderActionIcons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 0.2rem;
  padding-top: 0.1rem;
  padding-right: 0.8rem;
  gap: 0.5rem;
}

.calenderActionIcons button {
  border: none;
  background-color: transparent;
}

.calenderActionIcons button img {
  width: 1.1rem;
  height: 1.1rem;
}

.logsOftheDay_text p {
  margin: 0;
  color: var(--grey-text);
  font-size: 14px;
}

.logsOftheDay_text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 1200px) {
  .logsOftheDay_text {
    padding: 0 0.5rem;
  }
}

.logsOftheDay {
  margin: 1rem 0;
}

.todayDate {
  background-color: var(--greenpistachio-bg) !important;
}

.calendertabs_container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-top: 1px solid var(--primary-color);
  padding: 0 0.5rem;
}

.calendertabsBtn {
  background-color: var(--light-bg);
  padding: 0.5rem 1rem;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  width: 120px;
  text-align: center;
  cursor: pointer;
}

.calendertabsBtn p {
  margin: 0;
  color: var(--light-black);
  font-size: 14px;
  font-weight: 500;
}

.activeCalendarTab {
  background-color: var(--primary-color) !important;
}

.activeCalendarTab p {
  color: var(--white-text) !important;
}

/* ------------------------------------------Special Hour Pop-Up-------------------------------------- */

.modalDatePicker {
  display: flex;
  align-items: center;
  gap: 0.7rem;
  /* margin: 1.5rem 0; */
  margin-top: 1.5;
}

.modalDatePicker img {
  width: 1.5rem;
  cursor: pointer;
}

.input_field_date {
  border: 1px solid var(--light-text);
  border-radius: 6px;
  padding-left: 35px;
  height: 28px;
  background-color: var(--primary-bg);
  width: fit-content;
}

.image_icon_date {
  position: relative;
}

.image_icon_date::before {
  content: "";
  width: 16px;
  height: 16px;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  left: 10px;

  top: 6px;
}

.AddTimeRow {
  border: none;
  background-color: transparent;
}

.AddTimeRow img {
  border-radius: 50%;
  width: 1.2rem;
}

.deletetimeRow img {
  width: 1.5rem;
}

.timingsRowBody {
  /* border: 1px solid var(--light-text); */
  border-radius: 7px;
}

.timings_inputs {
  margin-left: 0.6rem;
  position: relative;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
}
.accordion-button {
  margin-right: 0.4rem;
}
.textDeco {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  width: 100%;
}
.txtt2 select,
.txt select {
  border-radius: 6px;
  border: 1px solid var(--light-text);
  color: #666666;
  width: 60px;
  height: 37px;
  padding-top: 0.3rem;
  background-color: var(--primary-bg);
}

.txtt2 label,
.txt label,
.txtDis label {
  color: #666666;
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  position: absolute;
  top: -8px;
  left: 12px;
  background-color: white;
  padding-left: 5px;
  padding-right: 5px;
  z-index: 20;
}

.txt,
.txtt2 {
  position: relative;
  width: 100%;
}
.txtDis label {
  top: -4px !important;
}

.discountinput {
  position: relative;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 1rem;
}

.discountinput label {
  position: absolute;
  font-family: "Poppins", sans-serif;
  top: -5px;
  left: 5px;
  background-color: white;
  padding: 0 4px;
  font-size: 10px;
  z-index: 10;
}

.discountinput input {
  background-color: var(--secondary-color);
  min-width: 60px;
  max-width: 120px;
  height: 54px;
  font-size: 16px;
  padding: 0 0.5rem;
  border: 1px solid #737373;
  border-radius: 6px;
  color: #737373;
}

.discountinput input::-webkit-outer-spin-button,
.discountinput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  /* Optional - if you want to remove the default margin */
}

.onlineImg img {
  width: 1.7rem;
  height: 1.7rem;
}

.onlineImg button {
  border: none;
  background-color: transparent;
}

.onlineImg select {
  border: none;
  background-color: var(--secondary-bg);
  padding: 0.3rem;
  border-radius: 6px;
  outline: none;
  cursor: pointer;
}

.selectGuestArrival {
  position: relative;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
}

.selectGuestArrival label {
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  position: absolute;
  top: -5px;
  left: 5px;
  background-color: white;
  padding: 0 5px;
}
.timingsRowBodyContainer .accordion {
  border: 1px solid var(--light-text);
  border-radius: 7px;
  margin-bottom: 1rem;
}

.timingsRowBodyContainer .accordion .accordion-item {
  overflow: hidden;
  box-shadow: none;
  border: 1px solid #737373 !important;
}

.timeEditTable select {
  padding: 0;
  overflow: auto;
  min-width: 100px;
  color: #737373;
  height: 54px;
  width: 97%;

  font-size: 16px;

  border: 1px solid #737373 !important;
  cursor: pointer;
}

.timingsRowBodyContainer .accordion-button::after {
  margin: 0;
}

.timingsRowBodyContainer .accordion-button {
  border: none !important;
  box-shadow: none !important;
  padding: 0;
  width: fit-content;
}

.timingsRowBodyContainer .accordion-button::after {
  transform: rotate(0deg);
  background-image: url("../../Assets/orangeAngle.svg") !important;
  background-size: 100% 100%;
}

.timingsRowBodyContainer .accordion-button:not(.collapsed) {
  background-color: transparent;
  color: var(--primary-color) !important;
  transform: rotate(-270deg);
}

.addTimingRowContainer {
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.addTimingRowContainer button {
  border-radius: 50%;
  width: 30px;
  padding: 3px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.paddingLeft0 {
  padding-left: 0 !important;
}

.paddingRight0 {
  padding-right: 0;
}

.checkbox-labelTiming {
  position: relative;
}

.checkbox-labelTiming::before {
  content: "";
  position: absolute;
  top: 0;
  height: 17px;
  width: 17px;
  right: -20px;
  border: 1.4px solid #737373;
  /* background-color: var(--primary-bg); */
  border-radius: 4px;
  margin-left: 4px;
}

.checkbox-labelTiming::after {
  content: "✓";
  position: absolute;
  right: -20px;
  top: 0px;
  width: 17px;
  height: 17px;
  opacity: 0;
  transition: opacity 0.2s;
  font-size: 18px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.hidden-checkbox:checked + .checkbox-labelTiming::after {
  opacity: 1 !important;
  color: var(--primary-color) !important;
}

.checkbox-labelTiming {
  padding-right: 4px;
  font-family: "Poppins", sans-serif;
  color: #256fef;
  font-size: 12px;
}
/*--------------------------------------------New design Calander---------------*/
.discountAndSeat {
  display: flex;

  gap: 1rem;
  justify-content: center;
}
@media (min-width: 1200px) {
  .specialModal.modal,
  .specialModal .modal-dialog {
    --bs-modal-width: 1000px !important;
  }
}

@media (max-width: 1200px) {
  .specialModal.modal,
  .specialModal .modal-dialog {
    --bs-modal-width: 90% !important;
  }

  .specialModalFooter,
  .specialModal .modal-body {
    padding: 0 3rem;
  }
}

@media (max-width: 991px) {
  .specialModal.modal,
  .specialModal .modal-dialog {
    --bs-modal-width: 98% !important;
  }

  .specialModalFooter,
  .specialModal .modal-body {
    padding: 0 1.5rem;
  }

  .OpratioNHourconttrr {
    overflow-x: auto;
    max-width: 600px;
    -webkit-overflow-scrolling: touch;
  }

  .timeCOntrrrr {
    overflow: hidden;
    min-width: 301px;
  }
  .mobileMArginClss {
    margin-top: 10px !important;
  }
  .paddingMobilnone {
    padding-top: 0 !important;
  }

  .OpratioNHourconttrr::-webkit-scrollbar {
    display: none;
  }

  .txt label,
  .txtt2 label {
    top: -7px !important;
  }
}

/* @media (min-width: 992px) {
  .specialModal.modal,
  .specialModal .modal-dialog {
    --bs-modal-width: 95% !important;
  }

  .specialModal .modal-body {
    padding: 0 3rem;
  }
} */

.timeEditTable {
  margin-top: -1rem;
  color: var(--light-text);
}

.timeSlot {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.timeSlot img {
  width: 1.5rem;
}

.timeSlot p {
  margin: 0;
  color: var(--light-text);
  font-weight: 500;
  font-size: 17px;
}

.timeDropdownBody {
  padding: 0 1.5rem;
}

.timeDropdownBody ul {
  list-style: none;
  padding: 0;
}

.timeDropdownBody ul li {
  border-top: 1px solid var(--fade-text);
}

.opacity0 {
  opacity: 0;
  pointer-events: none;
}

.saveSpecialTimeBtn {
  background-color: var(--primary-color);
  /* border-color: var(--primary-color); */
  border: transparent;
}
.saveSpecialTimeBtn:hover {
  background-color: var(--primary-colorHover);
}

.specialModalFooter .cancelSpecialTimeBtn {
  background-color: var(--primary-bg);
  color: var(--primary-text);
  border: 2px solid var(--primary-text);
}
.cancelSpecialTimeBtn {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  margin-right: 1rem;
}
.specialModalFooter button {
  width: 120px;
}

.specialModalFooter {
  border: none;
  margin: 1rem 0;
}

.dateContainer {
  box-shadow: 0px 0px 5px 0px #00000033;
  width: fit-content;
  padding: 0.3rem 1rem;
  border-radius: 6px;
  display: flex;
  align-items: center;
}

.month-nav-arrow-buttons {
  margin: 0 !important;
}

.month-nav-arrow-buttons button {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
}

.month-nav-arrow-buttons button img {
  width: 8px;
}

.dateContainer select {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 14px;
  text-align: center;
  color: var(--light-text);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  pointer-events: none;
}

.dateContainer .month-select {
  padding: 0 0.3rem;
}

.dateContainer .year-select {
  margin-left: 0.5rem;
}

.occupencyColor {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: var(--lightGrey-bg);
}

.occupency25Color {
  background-color: var(--fadeGreen-bg) !important;
}

.occupency50Color {
  background-color: var(--fadeBrown-bg) !important;
}

.occupency75Color {
  background-color: var(--lightPink-bg) !important;
}

.occupency100Color {
  background-color: var(--pink-bg) !important;
}

.occypencyDivContainer {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.occupencyItem {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.occupencyItem p {
  margin: 0;
  font-size: 14px;
  color: var(--light-text);
}

.inside_occupencyItem div,
.inside_occupencyItem {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.inside_occupencyItem div {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.calenderBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.3rem 1.5rem;
  position: sticky;
  top: 123px;
  z-index: 91;
  background-color: var(--primary-bg);
}

.days-of-week {
  position: sticky;
  top: 180px;
  z-index: 91;
}
.contntBox {
  height: calc(100vh - 247px);
  overflow-y: scroll;
}

@media screen and (max-width: 1100px) {
  .day-grid-item-container .day-grid-item-header {
    width: 35px;
    height: 35px;
  }

  .monthnumber,
  .monthname {
    text-align: center !important;
  }

  .monthnumber {
    font-size: 12px;
  }

  .monthname {
    font-size: 10px;
  }

  .day-grid-item-container .day-grid-item-header {
    top: -3px;
    left: 8px;
  }
}

.navigatorDayCont {
  display: flex;
  align-items: center;
  justify-content: center;
}
