/* Add this CSS to your project's CSS file (e.g., App.css) */
.reviews_section {
  margin-top: 2rem;
}

.progress_container {
  width: 90%;
  height: fit-content;
  background-color: #d6d6d6;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 1rem;
  /* Add some space between progress bars */
}

.progress_bar {
  height: 10px;
  width: 0%;
  background-color: var(--Green-Color, rgba(100, 156, 91, 1));
  border-radius: 5px;
  transition: width 0.3s ease;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: var(--Green-Color, rgba(100, 156, 91, 1));
}

.CircularProgressbar .CircularProgressbar-text {
  fill: var(--text-black);
}

.progress_text {
  text-align: center;
  display: flex;
  font-size: 14px;
  flex-direction: row;
  font-family: "Roboto";
  font-weight: 400;
  margin-bottom: 1rem;
}

.progress_text img {
  width: 0.9rem;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.progressbar_text_wrapper {
  display: flex;
  gap: 0.2rem;
  align-items: center;
}

/* circular bar  */
.Circualr_progressBar_section {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
}

.text_rating_sectin {
  padding: 0px 10px;
  display: flex;
  gap: 2.2rem;
  justify-content: space-evenly;
  flex-direction: row;
  margin-top: 3rem;
}

.text_rating_sectin h6,
.text_rating_sectin p {
  margin: 0;
}
.experience img {
  width: 99%;
}
.no_reviews {
  display: flex;
  flex-direction: column;
}

.no_reviews h6 {
  margin: 0;
}

.no_reviews p {
  font-size: 14px;
  color: var(--light-black);
  font-family: "Roboto";
}

.linear_progressBar_section {
  padding-left: 30px;
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
}

.left_section_circularProgressBar {
  border-right: 1px solid #a2a2a2 !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.user_review_container {
  padding: 0px 20px;
  margin-top: 3rem;
}

.user_review_wrapper {
  display: flex;
  gap: 0.4rem;
  flex-direction: row;
  align-items: center;
}

.user_review_rowcontainer {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.user_review_rowcontainer_name {
  display: flex;
  font-family: "Roboto";
  font-size: 14px;
  flex-direction: row;
  gap: 0.5rem;
}

.user_review_rowcontainer_name h6 {
  margin: 0;
}

.user_review_rowcontainer_date {
  font-family: "Roboto";
  font-size: 14px;
  color: var(--text-grey);
}
.borddrBottm {
  border-bottom: 1px solid #d3d4d4;
}

.user_review_like_report_section {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  cursor: pointer;

  align-items: center;
}

.user_review_description {
  margin-top: 1rem;
}

.user_review_like {
  display: flex;
  gap: 0.5rem;
  flex-direction: row;
  align-items: center;
}

.user_review_like p {
  margin: 0;
}

.see_more_reviews {
  display: flex;
  margin-top: 1rem;
  flex-direction: row;
  justify-content: right;
}

.see_more_reviews img {
  margin-left: 0.3rem;
}

.write_review_button {
  display: flex;
  margin-top: 1rem;
  justify-content: right;
}

.write_review_button button {
  width: fit-content;
  padding: 0.4rem 1rem;
  border: none;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: var(--white);
}

#load_more_button {
  margin-top: 0;
  width: fit-content;
  border: none;
  text-decoration: underline;
  border-radius: 5px;
  background-color: transparent;
  color: rgb(33, 135, 231);
}

/* reviews modal css */
.stars_text_wrapper {
  display: flex;
  justify-content: center;
  gap: 7rem;
  font-family: "Roboto";
  font-weight: 400;
}

.stars_rating_section {
  margin-top: 2rem;
}

.user_detail_section {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin-left: 15%;
  justify-content: left;
}

@media screen and (max-width: 576px) {
  .columnAfter576 {
    display: flex;
    flex-direction: column;
  }

  .marginTop576 {
    margin-top: 1.5rem;
  }
}

.CircularProgressbar {
  max-width: 200px;
}

@media screen and (max-width: 1200px) {
  .CircularProgressbar {
    max-width: 170px !important;
  }
}

@media screen and (max-width: 991px) {
  .CircularProgressbar {
    max-width: 150px !important;
  }
}

.red_tick_section {
  border: 1px solid lightgrey;
  padding: 2rem 1rem;
  border-radius: 5px;
  margin: 2rem 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  font-size: 16px;

  p {
    margin: 0;
  }
}
.LikeImgg {
  height: 14px !important;
  width: 14px !important;
}
.LikeI {
  margin-right: 2px;
  height: 18px;
  width: 18px;
}

@media screen and (max-width: 576px) {
  .circular_review_wrapper {
    height: 100%;
  }
  .linear_progressBar_section {
    padding-left: 10px;
  }
  .experience img {
    margin-top: 60px;
  }
  .no_reviews p {
    margin: 0;
  }

  .CircularProgressbar {
    width: 100px;
    height: 100px;
  }

  .Circualr_progressBar_section {
    flex-direction: column;
    gap: 0.7rem;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

.psotBtn:hover {
  background-color: var(--primary-colorHover) !important;
}
