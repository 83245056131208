.blog_heading_name_date {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    font-size: 12px;
    color: var(--text-grey);
}

.main_picture_blog2 img {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    object-fit: cover;
}

.main_picture_blog2 {
    display: flex;
    justify-content: center;
    height: 60vh;
}

@media screen and (max-width: 1100px) {
    .main_picture_blog2 {
        display: flex;
        justify-content: center;
        height: 50vh;
    }
}