.background-image-container {
  display: flex;
  align-items: center;
  position: relative;
  background-image: url("/src/Assets/getHelpBg.png") !important;
  /* Replace with your image path */
  background-size: cover;
  background-position: center;
  height: 60vh;
  /* Adjust height as needed */
  width: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Adjust overlay color and opacity */
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  z-index: 2;
}

.row_text {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 1rem;
}

.contact_section_left {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.gethelp_right_section {
  background-color: var(--white);
  color: var(--text-black);
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 500;
  gap: 1rem;
  padding: 1rem 1rem;
  border-radius: 10px;
  width: 100%;
}

.gethelp_right_section p {
  margin-bottom: 3rem;
}

.gethelp_right_section img {
  margin-bottom: 1rem;
}

.gethelp_right_section a {
  text-decoration: none;
  background-color: var(--primary-color);
  color: var(--white);
  font-family: "Roboto";
  font-size: 14px;
  margin-top: auto;
  font-weight: 400;
  margin-top: 1.5rem;
  border: none;
  padding: 0.4rem 1rem;
  border-radius: 5px;
}

.faq_section {
  margin-top: 2rem;
}

.faq_section_container_heading h2 {
  font-family: "Roboto";
  font-weight: 600;
  margin-bottom: 2rem;
}

.faq_section_container {
  display: flex;
  justify-content: center;
}

.accordion-title {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;
  padding: 1rem;
  font-weight: 600;
}

.dropdown-icon {
  transition: transform 0.3s ease;
}

.arrow {
  width: 30px;
  height: 30px;
  transition: transform 0.3s ease;
  font-weight: 600;
}

.arrow.up {
  transform: rotate(180deg);
}

.accordion-content {
  padding: 1rem;
}

.accordion-content-text {
  margin: 0;
}

.list_your_venueSection_faqPage {
  margin-top: 2rem;
}

.accordion-item {
  border: none !important;
  box-shadow: 0px 2px 2px 0px #0000002e;
  margin-bottom: 1rem;
}

@media screen and (max-width: 768px) {
  .contact_section_left {
    gap: 0px;
  }
  .gethelp_right_section p {
    margin-bottom: 2rem;
  }
}
