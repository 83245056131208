.testimonial-container {
  margin-top: 2rem;
  background-color: var(--light-blue);
  padding: 50px 20px;
  .slick-prev,
  .slick-next {
    box-shadow: none !important;
    background-color: transparent !important;
  }
  h2 {
    font-size: 30px;
    margin-bottom: 3rem;
  }
}
.borderClss {
  img {
    border-radius: 50% !important;
    overflow: hidden;
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
  }
}

.card {
  height: 100%;
  border: none;
  border-radius: 10px;
}
.minHeightClss {
  min-height: 250px;
}
.flxxClss {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card-body {
  height: 100%;
  font-size: 14px;
  color: #333;
}

.slick-prev,
.slick-next {
  z-index: 1;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.testiAutherImg {
  max-width: 40px;
}

.discribtionTest {
  font-size: 14px;
  font-weight: 300;
}
.borderClss {
  border-top: 0.1px solid #f2f4f7;
  padding-top: 0.8rem;
}
@media screen and (max-width: 768px) {
  .testimonial-container {
    padding: 25px 7px;
  }
}
